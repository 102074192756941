import { motion } from "framer-motion";
import FormSelect from "../../reusableComponents/formSelect/formSelect";
import { useState, useEffect } from "react";

const RefinanceType = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const [refinanceType, setRefinanceType] = useState('');
  const [isRefinanceTypeValid, setIsRefinanceTypeValid] = useState(true);

  const handleRefinanceTypeChange = (event) => {
    setRefinanceType(event.target.value);
  };

  useEffect(() => {
    setFormData({ ...formData, selectedRefinanceType: refinanceType });
  }, [refinanceType]);

  const refinanceTypeOptions = [
    { label: 'Select Refinance Type', value: 'Select Refinance Type>' },
    { label: 'Rate/Term', value: 'Rate/Term' },
    { label: 'Cash Out', value: 'Cash Out' }
  ];

  const handleNext = () => {
    if (refinanceType) {
      setPage(page + 1);
      setProgressBar(1000);
      setIsRefinanceTypeValid(true);
    } else {
      setIsRefinanceTypeValid(false);
    }
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">Refinance Type ?</div>
      <div className="form-container">
        <h2 className="form-content-title">What's your Refinance Type?</h2>
        <div className="form-fields">
          <FormSelect
          label="SELECT REFINANCE TYPE *"
          value={refinanceType}
          options={refinanceTypeOptions}
          onChange={handleRefinanceTypeChange}
          error={!isRefinanceTypeValid}
          >
          </FormSelect>
        </div>
          {isRefinanceTypeValid || <p className="error-message">Please Select Refinance Type.</p>}
        <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={() => {
              setPage(page - 1);
              setProgressBar(-1000);
            }}
          >
            Previous
          </button>
          <button className="form-button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default RefinanceType;
