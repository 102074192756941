import React from 'react'
import './debtResultsSteps.scss'

const DebtResultOne = ({ debtResult }) => {
  return (
    <div className='debt-result-component'>
        <div className="debt-result-headings">
            <h1 className="title">Debt Section: Commanding Control – 86-100% Score Range</h1>
            <p className='paragraph'>A score of 86-100% in the Debt Section indicates that you are in command of your financial obligations. Managing debt effectively is key to mortgage approval, and this score reflects a strong position.</p>
        </div>
        <div className="percentage">
          <h5>Debt Score</h5>
          <h2>{debtResult}%</h2>
          <div className="high-box">
            High
          </div>
        </div>
    </div>
  )
}

export default DebtResultOne