import React from 'react';
import './formSelectorStyles.scss'

function FormSelector({ label, value, options, onChange, error }) {
  return (
    <div className='custom-form-selector'>
      <label>{label}</label>
      <select value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <p>{error}</p>
    </div>
  );
}

export default FormSelector