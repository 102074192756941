import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik';
import * as Yup from 'yup';
import FormInput from "../../reusableComponents/formInput/formInput";
import FormSelector from "../../reusableComponents/formSelector/formSelector";
import { useState } from "react";


const suffixTypeOptions = [
  { label: 'Please Select', value: 'Please Select>' },
  { label: 'Jr.', value: 'Jr.' },
  { label: 'Sr.', value: 'Sr.' },
  { label: 'I', value: 'I' },
  { label: 'II', value: 'II' },
  { label: 'III', value: 'III' },
];

const martialStatusTypeOptions = [
  { label: 'Please Select', value: 'Please Select>' },
  { label: 'Single', value: 'Single' },
  { label: 'Married', value: 'Married' },
  { label: 'Widowed', value: 'Widowed' },
  { label: 'Divorced', value: 'Divorced' },
  { label: 'Separated', value: 'Separated' },
  { label: 'Registered Partnership', value: 'Registered Partnership' },
];

const citizenshipTypeOptions = [
  { label: 'Please Select', value: 'Please Select>' },
  { label: 'Citizen', value: 'Citizen' },
  { label: 'Permanent Resident', value: 'Permanent Resident' },
  { label: 'J Visa', value: 'J Visa' },
  { label: 'H1B Visa', value: 'H1B Visa' },
  { label: 'Not Residing in US', value: 'Not Residing in US' },
];


const PersonalInformation = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {


  const validationSchema = Yup.object().shape({
    legalFirstName: Yup.string().required("Legal First Name is required"),
    middleName: Yup.string().required("Middle Name is required"),
    legalLastName: Yup.string().required("Legal Last Name is required"),
    socialSecurityNumber: Yup.number().required("Social Security Number is required"),
    dateOfBirth: Yup.string().required("Date of birth is required"),
    listBorrowNames: Yup.string().required("List Borrow Names is required"),
    email: Yup.string().required("Email is required"),
    phoneNumber: Yup.number().required("Phone Number is required"),
    totalNumberOfBorrowers: Yup.number().required("Total Number Of Borrowers is required"),
    jointCredit: Yup.string().required("Joint Credit is required"),
    numberOfDependents: Yup.number().required("Number Of Dependents is required"),
    ageOfDependents: Yup.number().required("Age Of Dependents is required"),
  });
  
  const formik = useFormik({
    initialValues: {
      legalFirstName: "",
      middleName: "",
      legalLastName: "",
      socialSecurityNumber: "",
      dateOfBirth: "",
      listBorrowNames: "",
      email: "",
      phoneNumber: "",
      totalNumberOfBorrowers: "",
      jointCredit: "",
      numberOfDependents: "",
      ageOfDependents: "",
    },
    validationSchema,
    onSubmit: (values) => {
      const search = {
        loanAmount: values.legalFirstName - values.socialSecurityNumber,
        dateOfBirth: values.dateOfBirth,
        middleName: values.middleName,
      };
    },
  });
  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">Let's <br /> Get <br /> Started!</div>
      <div className="form-container">
        <h2 className="form-content-title">Personal Information</h2>
        <p>Please provide a few details about yourself.</p>
        <div className="calculation-form">
          <div className="form-fields">
            <FormInput
              label="Legal First Name"
              name="legalFirstName"
              value={formik.values.legalFirstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.legalFirstName && formik.errors.legalFirstName}
              placeholder="Legal First Name"
            />

            <FormInput
              label="Middle Name"
              name="middleName"
              value={formik.values.middleName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.middleName && formik.errors.middleName}
              placeholder="Middle Name"
            />
          </div>
          <div className="form-fields">
            <FormInput
              label="Legal Last Name"
              name="legalLastName"
              value={formik.values.legalLastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.legalLastName && formik.errors.legalLastName}
              placeholder="Legal Last Name"
            />

            <FormSelector
                  id="suffixTypeSelect"
                  label="Suffix"
                  name="suffix"
                  value={formik.values.suffix}
                  options={suffixTypeOptions}
                />
          </div>
          <div className="form-fields">
            <FormInput
              label="Social Security Number"
              name="socialSecurityNumber"
              value={formik.values.socialSecurityNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.socialSecurityNumber && formik.errors.socialSecurityNumber}
              placeholder="Social Security Number"
              type="number"
            />

            <FormInput
              label="Date of birth"
              name="dateOfBirth"
              value={formik.values.dateOfBirth}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.dateOfBirth && formik.errors.dateOfBirth}
              placeholder="Date of birth"
              type="date"
            />
          </div>
            <FormInput
              label="List Name(s) of Other Borrower(s) Applying for this Loan (First, Middle, Last, Suffix) - Use a separator between names"
              name="listBorrowNames"
              value={formik.values.listBorrowNames}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.listBorrowNames && formik.errors.listBorrowNames}
              placeholder="List Name(s) of Other Borrower(s) "
            />
          <div className="form-fields">
            <FormInput
              label="E Mail"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && formik.errors.email}
              placeholder="E Mail"
            />

            <FormInput
              label="Phone Number"
              name="phoneNumber"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phoneNumber && formik.errors.phoneNumber}
              placeholder="Phone Number"
              type="number"
            />
          </div>
          <div className="form-fields">
                <FormSelector
                  id="martialStatusSelect"
                  label="Martial Status"
                  name="martialStatus"
                  value={formik.values.martialStatus}
                  options={martialStatusTypeOptions}
                />

            <FormInput
              label="Total Number of Borrowers"
              name="totalNumberOfBorrowers"
              value={formik.values.totalNumberOfBorrowers}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.totalNumberOfBorrowers && formik.errors.totalNumberOfBorrowers}
              placeholder="Total Number of Borrowers"
              type="number"
            />
          </div>
            <FormInput
              label="Each Borrower intends to apply for joint credit 'Dependents (not listed by another Borrower)'"
              name="jointCredit"
              value={formik.values.jointCredit}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.jointCredit && formik.errors.jointCredit}
              placeholder="Each Borrower intends to apply for joint credit "
            />
          <div className="form-fields">
            <FormInput
              label="Number of Dependents"
              name="numberOfDependents"
              value={formik.values.numberOfDependents}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.numberOfDependents && formik.errors.numberOfDependents}
              placeholder="Number of Dependents"
              type="number"
            />
            <FormInput
              label="Age of Dependents"
              name="ageOfDependents"
              value={formik.values.ageOfDependents}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.ageOfDependents && formik.errors.ageOfDependents}
              placeholder="Age of Dependents"
              type="number"
            />
          </div>
              <FormSelector
                id="citizenshipSelect"
                label="Citizenship"
                name="citizenship"
                value={formik.values.citizenship}
                options={citizenshipTypeOptions}
              />
          

          <h5 className="form-content-title">Type of Credit</h5>
              <p>Credit Single or Joint</p>
        <Formik
          initialValues={{
            selectedOption: '',
          }}
          validationSchema={validationSchema}
        >
          {({ values, touched }) => (
            <Form>
              <div className="radio-options">
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="I am applying for individual credit." />
                  I am applying for individual credit.
                </label>
                <label className="radio-option"> 
                  <Field type="radio" name="selectedOption" value="I am applying for joint credit." />
                  I am applying for joint credit.
                </label>
              {touched.selectedOption && !values.selectedOption && (
                <div className="error-message">Please select an option</div>
              )}
              </div>
            </Form>
          )}
        </Formik>
          <div className="form-buttons">
                <button className="form-button" type="submit" onClick={formik.handleSubmit}>
                  Next
                </button>
              </div>
        </div>
      </div>
    </motion.div>
  );
};

export default PersonalInformation;
