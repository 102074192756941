import { motion } from "framer-motion";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const Veteran = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required('Please select an option'),
  });

  const handleNext = (values) => {
    setFormData({ ...formData, veteran: values.selectedOption });
    if (values.selectedOption == "Yes"){
    setPage(page + 1);
    setProgressBar(1000);
    }
    else{
      setPage(page + 5);
      setProgressBar(5000);
    }
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      {/* <div className="step-title">Veteran</div> */}
      <div className="form-container">
        <h2 className="form-content-title">Are You A Veteran? *</h2>
        <Formik
          initialValues={{
            selectedOption: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, touched }) => (
            <Form>
              <div className="radio-options">
                <label className="radio-option" onClick={() => setFormData({ ...formData, veteranValue: 5 })}>
                  <Field type="radio" name="selectedOption" value="Yes" />
                  Yes
                </label>
                <label className="radio-option" onClick={() => setFormData({ ...formData, veteranValue: 5 })}> 
                  <Field type="radio" name="selectedOption" value="No" />
                  No
                </label>
              {touched.selectedOption && !values.selectedOption && (
                <div className="error-message">Please select an option</div>
              )}
              </div>
          <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={() => {
              setPage(page - 1);
              setProgressBar(-1000);
            }}
          >
            Previous
          </button>
                <button className="form-button" type="submit" disabled={!values.selectedOption && touched.selectedOption}>
                  Next
                </button>
        </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};

export default Veteran;
