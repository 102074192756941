import React from 'react'
import './debtResultsSteps.scss'

const DebtResultThree = ({ debtResult }) => {
  return (
    <div className='debt-result-component'>
        <div className="debt-result-headings">
            <h1 className="title">Debt Section: A Road to Understanding – 0-69% Score Range</h1>
            <p className='paragraph'>A score in the 0-69% range in the Debt Section indicates a phase of understanding and improvement. It's a chance to work closely with your Loan Officer to navigate your current debt landscape.</p>
        </div>
        <div className="percentage">
          <h5>Debt Score</h5>
          <h2>{debtResult}%</h2>
          <div className="low-box">
            Low
          </div>
        </div>
    </div>
  )
}

export default DebtResultThree