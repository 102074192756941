import React from 'react'
import './loanOptionsStyles.scss'

const VALoan = () => {
  return (
    <div className="loanOptionsTypes-container" id="va-loan">
        <h3>VA LOAN</h3>
        <h1 className='title'>VA Loan</h1>
        <h5>Available only to active military and veterans, these loans offer up to 103.5% financing, no money down purchases and 100% cash out.</h5>
        <div className="advantages-list">
          <h3 className='advantages-title'>ADVANTAGES:</h3>
          <div className="item">
            <h1>01.</h1>
            <h4>Backed by the VA.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>02.</h1>
            <h4>Offers ability to streamline refinance anytime in the future.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>03.</h1>
            <h4>Relaxed underwriting guidelines down to 580 score.</h4>
          </div>
          <div className="divider"></div>
        </div>
        <button className='find-rate-button'>APPLY NOW</button>
    </div>
  )
}

export default VALoan