import React from "react";
import { MainLayout } from "../../components";
import { HappyCustomer } from "../../sections/happy-customer";
import { Analytics } from "../../sections/analytics";
import FreeQuotes from "../../components/home/freeQuotes/freeQuotes";
import Divider from "../../components/reusableComponents/divider";

const Home = () => {
  return (
    <MainLayout>
      <Divider />
      <FreeQuotes />
      <Analytics />
      <Divider />
      <HappyCustomer />
      <Divider />
    </MainLayout>
  );
};

export default Home;
