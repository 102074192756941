import React from 'react'
import footerFacebook from '../../../images/footerFacebook.png'
import footerTwitter from '../../../images/footerTwitter.png'
import footerLinkedin from '../../../images/footerLinkedin.png'
import footerInstagram from '../../../images/footerInstagram.png'
import footerP from '../../../images/footerP.png'
import footerLogo from '../../../images/footerLogo.png'
import { Link } from 'react-router-dom'
import './AdminFooterStyles.scss'

const AdminFooter = ({formData, selectedImage, selectedLogo, selectedLenderLogo, selectedBackgroundImage}) => {

    const BackgroundImage = {
        backgroundImage: `url(${selectedBackgroundImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '100%',
        height: '100%', // Set the desired height for your container
      };


  return (
    <div className="admin-footer" style={BackgroundImage}>
      <h1 style={{textAlign: 'center', color: '#fff', margin: '50px'}}>Your Website will look like as:</h1>
    <div className='admin-footer-container'>
        <div className="footer-Cards">
            <div className="footer-card">
                <h2>Loan Officer Details</h2>
                <div className="loan-officer-image">
                    <img src={selectedImage} alt="Home Loan"  className='menImage'/>
                    <img src={selectedLogo} alt="Home Loan" className='logo'/>
                </div>
                <h2>About Me</h2>
                <h6>{formData?.aboutYourself}</h6>
                <h6>
                    {formData?.name} <br />
                    {formData?.designation} <br />
                    {formData?.email} <br />
                    {formData?.phone} <br />
                    {formData?.address}
                </h6>
            </div>
            <div className="footer-card">
                <h2>Company Details</h2>
                <img src={selectedLogo} alt="Home Loan" className='homeLoanImage'/>
                <h2>About Us</h2>
                <h6>{formData?.aboutCompany}</h6>
                <h6>
                    {formData?.companyName} <br />
                    {formData?.companyAddress} <br />
                    {formData?.companyPhone} <br />
                    {formData?.companyID}
                </h6>
            </div>
            <div className="footer-card">
                <h2>Tools</h2>
                <Link className='link' to='/'><h6>Contact Us</h6></Link>
                <Link className='link' to='/'><h6>Mortgage Calculator</h6></Link>
                <Link className='link' to='/'><h6>Qualify Me</h6></Link>
                <Link className='link' to='/'><h6>Apply Now</h6></Link>
                <Link className='link' to='/'><h6>Join Our Facebook Group</h6></Link>
                <h2>Resources</h2>
                <Link className='link' to='/'><h6>Mortgage Understanding</h6></Link>
                <Link className='link' to='/'><h6>Loan Process</h6></Link>
                <Link className='link' to='/'><h6>Loan Program</h6></Link>
                <Link className='link' to='/'><h6>FAQs</h6></Link>
            </div>
        </div>
        <div className="footer-Cards">
            <div className="footer-card">
                <h3>Follow Me</h3>
                <div className="social-icons">
                <img  className='social-icon' src={footerFacebook} alt="Facebook"/>
                <img  className='social-icon' src={footerTwitter} alt="Twitter"/>
                <img  className='social-icon' src={footerLinkedin} alt="Linkedin"/>
                <img  className='social-icon' src={footerInstagram} alt="Instagram"/>
                <img  className='social-icon' src={footerP} alt="P"/>
                </div>
            </div>
            <div className="footer-lender">
                <img src={selectedLenderLogo} alt="Home Loan" className='lenderImage'/>
                <div className="lender-heading">
                    <h6>{formData?.lenderDescription}</h6>
                        <h6>
                            {formData?.lenderCompanyName} <br />
                            {formData?.lenderCompanyURL} <br />
                        </h6>
                </div>
            </div>
            <div className="footer-card">
                <h2>Disclaimer</h2>
                <Link className='link' to='/'><h6>ADA Accessibility Statement</h6></Link>
                <Link className='link' to='/'><h6>Privacy Policy</h6></Link>
                <Link className='link' to='/'><h6>Terms & Conditions</h6></Link>
                <Link className='link' to='/'><h6>Acceptable Use Policy</h6></Link>
            </div>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-bottom">
            <h6>© {formData.registeredYear} {formData.registeredCompanyName} - All Rights <br /> Reserved</h6>
            <img src={footerLogo} alt="LOGO" className='footerLogo'/>
        </div>
    </div>
    </div>
  )
}

export default AdminFooter