import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useEffect } from "react";

const LessCreditScore = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const validationSchema = Yup.object().shape({
    lessScore: Yup.array().min(1, 'Please select at least one reason.'),
  });

  const handleNext = (values) => {
    setFormData({ ...formData, lessCreditScore: values.lessScore });

    // Store the selected options in sessionStorage
    sessionStorage.setItem('selectedLessCreditScore', JSON.stringify(values.lessScore));

    setPage(page + 2);
    setProgressBar(2000);
  };

  const selectedLessCreditScoreFromSession = JSON.parse(sessionStorage.getItem('selectedLessCreditScore')) || [];

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="form-container">
        <h2 className="form-content-title">What forms of alternate lines can you produce? *</h2>
        <Formik
          initialValues={{
            lessScore: selectedLessCreditScoreFromSession,
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, touched, errors, setFieldValue }) => (
            <Form className="formik-form">
              <label className="checkbox-label">
                <Field className="checkbox" type="checkbox" name="lessScore" value="Car Insurance" />
                Car Insurance
              </label>
              <label className="checkbox-label">
                <Field className="checkbox" type="checkbox" name="lessScore" value="Utility Bills" />
                Utility Bills
              </label>
              <label className="checkbox-label">
                <Field className="checkbox" type="checkbox" name="lessScore" value="Rental History" />
                Rental History
              </label>
              <ErrorMessage name="lessScore" component="div" className="error-message" />
              
              <div className="form-buttons">
                <button
                  className="form-button-differ"
                  onClick={() => {
                    setPage(page - 1);
                    setProgressBar(-1000); 
                  }}
                >
                  Previous
                </button>
                <button className="form-button" type="submit" disabled={Object.keys(errors).length > 0}>
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};

export default LessCreditScore;
