import { motion } from "framer-motion";
import FormInput from "../../reusableComponents/formInput/formInput";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getQoutesRefinance } from "../../../common/APIS/GetQoutes/getQoutesRefinance";

const SeeQuote = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageTwo, setErrorMessageTwo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = () => {
    if (formData.email.trim() === "") {
      setErrorMessage("Email is required.");
    } else if (!validateEmail(formData.email)) {
      setErrorMessage("Invalid Email.");
    } else {
      setErrorMessage("");
    }

    if (formData.phoneNumber.trim() === "") {
      setErrorMessageTwo("Phone Number is required.");
    } else {
      setErrorMessageTwo("");
    }

    if (
      formData.email.trim() !== "" &&
      formData.phoneNumber.trim() !== "" &&
      validateEmail(formData.email)
    ) {
      setIsLoading(true);
    }

    const props = {
      formDataValues: formData
    }
    getQoutesRefinance(props)
    .then((resp) => {
      const data = resp;
      console.log("response", data);
      navigate("/refinanceProcessResult", { state: { data, formData } });
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      setIsLoading(false);
    });
  
  };

  useEffect(() => {
    console.log("first", formData)
  }, [formData]);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">
        let's see  this  quote.
      </div>
      <div className="form-container">
        <div className="form-title">
          Inflation Slayer, as promised. Please provide your email address and phone
          
          number to see your quotes.
        </div>
        <div className="input-field">
          <FormInput
            label="Email *"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            required
            error={errorMessage}
          />
          <FormInput
            label="Phone Number *"
            value={formData.phoneNumber}
            onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
            required
            error={errorMessageTwo}
            type="number"
          />
        </div>
        <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={() => {
              setPage(page - 1);
              setProgressBar(-1000);
            }}
          >
            Previous
          </button>
          <button className="form-button" type="submit" onClick={handleSubmit}>
            {isLoading ? "Loading..." : "Submit"}
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default SeeQuote;
