import React from 'react'
import './loanOptionsStyles.scss'

const FHAMortage = () => {
  return (
    <div className="loanOptionsTypes-container" id="fha-mortgage">
        <h3>FHA MORTGAGE</h3>
        <h1 className='title'>FHA Mortgage</h1>
        <h5>Backed by the Federal Housing Administration. FHA mortgages offer more lenient underwriting guidelines.</h5>
        <div className="advantages-list">
          <h3 className='advantages-title'>ADVANTAGES:</h3>
          <div className="item">
            <h1>01.</h1>
            <h4>Relaxed underwriting guidelines.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>02.</h1>
            <h4>Down payments as low as 3.5%.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>03.</h1>
            <h4>Great for first-time buyers.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>04.</h1>
            <h4>Credit scores as low as 580.</h4>
          </div>
          <div className="divider"></div>
        </div>
        <button className='find-rate-button'>APPLY NOW</button>
    </div>
  )
}

export default FHAMortage