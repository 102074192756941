import React from 'react'
import './debtResultsSteps.scss'

const DebtResultTwo = ({ debtResult }) => {
  return (
    <div className='debt-result-component'>
        <div className="debt-result-headings">
            <h1 className="title">Debt Section: Balancing Act – 70-85% Score Range</h1>
            <p className='paragraph'>Scoring 70-85% in the Debt Section illustrates a balancing act. You've been managing your debt responsibly, but there may be opportunities to optimize and align your financial picture even further.</p>
        </div>
        <div className="percentage">
          <h5>Debt Score</h5>
          <h2>{debtResult}%</h2>
          <div className="medium-box">
            Medium
          </div>
        </div>
    </div>
  )
}

export default DebtResultTwo