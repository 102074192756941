import React from 'react';
import './formInputStyles.scss'

function FormInput({ label, type, value, name, onChange, placeholder, error }) {
  return (
    <div className='form-input'>
      <label>{label}</label>
      <input value={value} type={type} name={name} onChange={onChange} placeholder={placeholder} required  />
      <p className='error'>{error}</p>
    </div>
  );
}

export default FormInput