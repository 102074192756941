import { motion } from "framer-motion";
import FormSelect from "../../reusableComponents/formSelect/formSelect";
import { useState, useEffect } from "react";

const CreditScore = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const [creditScore, setCreditScore] = useState('');
  const [isCreditScoreValid, setIsCreditScoreValid] = useState(true);

  const handleCreditScoreChange = (event) => {
    setCreditScore(event.target.value);
  };

  useEffect(() => {
    setFormData({ ...formData, selectedCreditScore: creditScore });
  }, [creditScore]);

  const creditScoreOptions = [
    { label: 'Select Credit Score', value: 'Select Credit Score>' },
    { label: '<640', value: '<640>' },
    { label: '640-679', value: '640-679' },
    { label: '680-719', value: '680-719' },
    { label: '720-739', value: '720-739' },
    { label: '740+', value: '740+' },
  ];

  const handleNext = () => {
    if (creditScore) {
      setPage(page + 1);
      setProgressBar(1000);
      setIsCreditScoreValid(true);
    } else {
      setIsCreditScoreValid(false);
    }
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">your credit ?</div>
      <div className="form-container">
        <h2 className="form-content-title">What's your score?</h2>
        <div className="form-fields">
          <FormSelect
          label="SELECT YOUR CREDIT SCORE *"
          value={creditScore}
          options={creditScoreOptions}
          onChange={handleCreditScoreChange}
          error={!isCreditScoreValid}
          >
          </FormSelect>
        </div>
          {isCreditScoreValid || <p className="error-message">Please select your credit score.</p>}
        <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={() => {
              setPage(page - 1);
              setProgressBar(-1000);
            }}
          >
            Previous
          </button>
          <button className="form-button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default CreditScore;
