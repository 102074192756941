import { motion } from "framer-motion";
import ToggleButtons from "../../reusableComponents/toggleButton/toggleButton";
import { useState, useEffect } from "react";

  const SaveLoan = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const [toggleState, setToggleState] = useState(false);
  
    const handleMilitryChange = () => {
      setToggleState(!toggleState);
    };
    useEffect(() => {
      setFormData({ ...formData, isMilitryLoan: toggleState })
    }, [toggleState]);
    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        <h1 className="step-title">Hi {formData.firstName}!</h1>
        <div className="form-container">
        <h2 className="form-content-title">want to save on your loan.?</h2>
        <p className="form-content" >
          Based on this home’s purchase price, you may be able to save up to $400.  
          We can connect you with a real estate agent who could help you save on 
          your new home.*
        </p>
        <div className="form-fields">
            <p>HAVE YOU OR YOUR SPOUSE SERVED IN THE MILITARY?</p>
            <ToggleButtons label1="NO" label2="YES" onChange={handleMilitryChange} />
        </div>
        <p className="form-content">
          *We use Home Connect. Home Connect is an 
          innovative platform that connects interested, well- 
          qualified buyers with local, professional real 
          estate agents.
        </p>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 1);
            setProgressBar(-1000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={() => {
            setPage(page + 1);
            setProgressBar(1000);
          }}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default SaveLoan;