import axios from 'axios';
import { parseStringPromise } from 'xml2js';

const api = axios.create({
  baseURL: 'http://18.220.59.147/',
});

export const getQoutesRefinance = async (props) => {
  let refinanceType;
  if (props?.formDataValues?.selectedRefinanceType === "Rate/Term") {
    refinanceType = 1;
  } else if (props?.formDataValues?.selectedRefinanceType === "Cash Out") {
    refinanceType = 2;
  } else {
    refinanceType = 1;
  }
  console.log("type", props.buttonLabel,refinanceType);
  try {
    const response = await api.get(
      `https://thirdparty.mortech-inc.com/mpg/servlet/mpgThirdPartyServlet`,
      {
        params: {
          loanpurpose: refinanceType,
          request_id: 1,
          customerId: 'Tyler',
          thirdPartyName: 'TylerLemkau',
          emailAddress: 'tylerle@zillowgroup.com',
          loan_amount: 200000,
          propertyState: 'CA',
          licenseKey: 'MortechTyler',
          loanProduct1: props.buttonLabel,
          loanProduct2: '25 year fixed',
          loanProduct3: '20 year fixed',
          loanProduct4: '15 year fixed',
          loanProduct5: '10 year fixed',
        },
      }
    );
    const xmlData = response.data;
    const jsonData = await parseStringPromise(xmlData, { explicitArray: false });

    console.log("Response", jsonData);
    return jsonData;
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
};
