import "./purchasingSteps.scss";
import { useState } from "react";
import SignUp from "./refinanceStepsForms/signUp";
import NewHome from "./refinanceStepsForms/newHome";
import CreditScore from "./refinanceStepsForms/creditScore";
import PropertyType from "./refinanceStepsForms/propertyType";
import EstimatedPrice from "./refinanceStepsForms/estimatedPrice";
import SeeQoute from "./refinanceStepsForms/seeQoute";
import YourProperty from "./refinanceStepsForms/yourProperty";
import CurrentMortage from "./refinanceStepsForms/currentMortage";
import CashOut from "./refinanceStepsForms/cashOut";
import RefinanceType from "./refinanceStepsForms/refinanceType";
 
function RefinanceSteps() {

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    isMilitry: "",
    selectedRefinanceType: "",
    selectedCreditScore: "",
    selectedPropertyType: "",
    purchasePrice: null,
    isMilitryLoan: "",
    email: "",
    phoneNumber: "",
    address: "",
    aptUnit: "",
    yourCity: "",
    yourState: "",
    yourZipCode: "",
    currentMortage: "",
    cashOut: ""
  });
  const [page, setPage] = useState(0);
  const [progrssBar, setProgressBar] = useState(0);

  const componentList = [
    <SignUp
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <NewHome
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <RefinanceType
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CreditScore
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <YourProperty
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <PropertyType
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <EstimatedPrice
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CurrentMortage
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CashOut
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <SeeQoute
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
  ];
console.log("refinance form", formData)
  return (
    <div className="purchasingSteps-container">
      <div className="progress-bar">
        {
          <div
            style={{
              width:
                page === 0
                  ? "0%"
                  : page === 1
                  ? "10%"
                  : page === 2
                  ? "20%"
                  : page === 3
                  ? "30%"
                  : page === 4
                  ? "40%"
                  : page === 5
                  ? "50%"
                  : page === 6
                  ? "60%"
                  : page === 7
                  ? "70%"
                  : page === 8
                  ? "80%"
                  : page === 9
                  ? "90%"
                  : "100%",
            }}
          ></div>
        }
      </div>

      <div className="forms">{componentList[page]}</div>
    </div>
  );
}

export default RefinanceSteps;