import React from 'react'
import PurchasingProcessResults from '../../components/purchasingProcessResult/purchasingProcessResults'
import MainLayout from '../../components/layout/layout'

const PurchasingProcessResult = () => {
  return (
    <MainLayout>
      <PurchasingProcessResults />
    </MainLayout>
  )
}

export default PurchasingProcessResult