import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const TotalResults = ({ credit, income, debtResult, assets, creditColor, incomeColor, debtColor, assetsColor }) => {
  const totalValue = (credit + income + debtResult + assets) / 4;
  const totalPercentage = totalValue.toFixed(2);

  const options = {
    animationEnabled: true,
    backgroundColor: '#fff',
    subtitles: [
      {
        text: `${totalPercentage}%`, 
        verticalAlign: 'center',
        fontSize: 20,
        dockInsidePlotArea: true,
        fontColor: '#0c1137'
      },
    ],
    data: [
      {
        type: 'doughnut',
        showInLegend: false,
        indexLabel: '{name}: {y}',
        yValueFormatString: "#,###'%'",
        dataPoints: [
          { name: 'Credit', y: credit, indexLabelFontColor: '#0c1137', color: creditColor },
          { name: 'Income', y: income, indexLabelFontColor: '#0c1137', color: incomeColor },
          { name: 'Debt', y: debtResult, indexLabelFontColor: '#0c1137', color: debtColor},
          { name: 'Assets', y: assets, indexLabelFontColor: '#0c1137', color: assetsColor },
        ],
        innerRadius: '50%',
      },
    ],
  };

  return <CanvasJSChart options={options} />;
};

export default TotalResults;
