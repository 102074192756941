import React from 'react'
import MainLayout from '../../components/layout/layout'
import ApplyNowSteps from '../../components/purchasingSteps/applyNowSteps'

const ApplyNow = () => {
  return (
    <MainLayout>
      <ApplyNowSteps />
    </MainLayout>
  )
}

export default ApplyNow