import "./scoreAppSteps.scss";
import { useState } from "react";
import CreditScore from "./scoreAppFormSteps/creditScore";
import LessCreditScore from "./scoreAppFormSteps/lessCreditScore";
import HowPaid from "./scoreAppFormSteps/howPaid";
import HourlyRate from "./scoreAppFormSteps/hourlyRate";
import WeeklyHours from "./scoreAppFormSteps/weeklyHours";
import GrossSalary from "./scoreAppFormSteps/grossSalary";
import BonusIncome from "./scoreAppFormSteps/bonusIncome";
import OvertimeIncome from "./scoreAppFormSteps/overtimeIncome";
import ScheduleMeeeting from "./scoreAppFormSteps/secheduleMeeting";
import Veteran from "./scoreAppFormSteps/veteran";
import PropertyType from "./scoreAppFormSteps/propertyType";
import PurchasePrice from "./scoreAppFormSteps/purchasePrice";
import BuyingArea from "./scoreAppFormSteps/buyingArea";
import RuralHomePurchasePrice from "./scoreAppFormSteps/ruralHomePurchasePrice";
import DownPayment from "./scoreAppFormSteps/downPayment";
import DebtAmmount from "./scoreAppFormSteps/debtAmount";
import TotalLiquidAssets from "./scoreAppFormSteps/totalLiquidAssets";
import LiquidAssets from "./scoreAppFormSteps/liquidAssets";
import NeedPa from "./scoreAppFormSteps/needpa";
import WantGift from "./scoreAppFormSteps/wantGift";
import GiftAmmount from "./scoreAppFormSteps/giftAmmount";
import ConventionalPurchasePrice from "./scoreAppFormSteps/conventionalPurchasePrice";
import FHAPurchasePrice from "./scoreAppFormSteps/fha";
import OwnAnotherHome from "./scoreAppFormSteps/ownAnotherHome";
import BonusIncomeAmmount from "./scoreAppFormSteps/bonusIncomeAmmount";
import OverTimeIncomeAmmount from "./scoreAppFormSteps/overTimeIncomeAmmount";
import CommissionIncome from "./scoreAppFormSteps/commissionncome";
import CommissionIncomeAmmount from "./scoreAppFormSteps/commissionIncomeAmmount";
import CategorisedLoan from "./scoreAppFormSteps/categorisedLoan";
import LessCreditFail from "./scoreAppFormSteps/lessCreditFailure";
import LessConventionalPass from "./scoreAppFormSteps/lessConventionalPass";
import LessConventionalFail from "./scoreAppFormSteps/lessConventionalFailure";
import VeteranPass from "./scoreAppFormSteps/veteranPass";
import VeteranFail from "./scoreAppFormSteps/veteranFailure";
import RuralHomePass from "./scoreAppFormSteps/ruralHomePass";
import RuralHomeFail from "./scoreAppFormSteps/ruralHomeFailure";
import FHAPass from "./scoreAppFormSteps/fhaPass";
import FHAFail from "./scoreAppFormSteps/fhaFailure";
import HowPaidOther from "./scoreAppFormSteps/howPaidOther";
import OtherAmount from "./scoreAppFormSteps/howPaidAmount";
import AlreadyReceivedGift from "./scoreAppFormSteps/alredyGift";
import WhyLessCredit from "./scoreAppFormSteps/whyLessCredit";
import ReasonsCredit from "./scoreAppFormSteps/reasonsCredit";
import LessCreditPass from "./scoreAppFormSteps/lessCreditPass";

function ScoreAppSteps() {

  const [formData, setFormData] = useState({
    creditScore: "",
    lessCreditScore: "",
    howPaid: "",
    howPaidArray: "",
    hourlyRate: "",
    weeklyHours: "",
    monthlySalary: "",
    bonusIncome: "",
    bonusIncomeAmount: "",
    overtimeIncome: "",
    otherPaidMethod: "",
    otherAmount: "",
    overtimeIncomeAmount: "",
    commissionIncome: "",
    commissionIncomeAmount: "",
    scheduleMeeting: "",
    debtCard: "",
    debtAmount: "",
    totalLiquidAssets: "",
    GIFTR: "",
    liquidAssets: "",
    needpa: "",
    wantGift: "",
    giftAmmount: "",
    veteran: "",
    failure: "",
    derogatoryFailure: "",
    vetFailure: "",
    RuralFailure: "",
    conventionalFail: "",
    fhaFail: "",
    selectedPropertyType: "",
    conventionalPurchasePrice: "",
    purchasePrice: "",
    buyingArea: "",
    ownAnotherHome: "",
    ruralHomePurchasePrice: "",
    downPayment: "",
    whyLessCredit: "",
    reasonsCreditLess: "",
    categorisedLoan: "",
    creditScoreValue: "",
    lessCreditScoreValue: "",
    howPaidValue: "",
    hourlyRateValue: "",
    weeklyHoursValue: "",
    monthlySalaryValue: "",
    bonusIncomeValue: "",
    overtimeIncomeValue: "",
    scheduleMeetingValue: "",
    debtCardValue: "",
    debtAmmountValue: "",
    totalLiquidAssetsValue: "",
    liquidAssetsValue: "",
    needpaValue: "",
    wantGiftValue: "",
    giftAmmountValue: "",
    veteranValue: "",
    selectedPropertyTypeValue: "",
    purchasePriceValue: "",
    buyingAreaValue: "",
    ownAnotherHomeValue: "",
    ruralHomePurchasePriceValue: "",
    downPaymentValue: "",
  });
  const [page, setPage] = useState(0);
  const [progrssBar, setProgressBar] = useState(0);

  const componentList = [
    <CreditScore
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <WhyLessCredit
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <ReasonsCredit
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <LessCreditScore
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <LessCreditFail
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <LessCreditPass
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <HowPaid
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <HourlyRate
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <WeeklyHours
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <OvertimeIncome
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <OverTimeIncomeAmmount
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <GrossSalary
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CommissionIncome
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CommissionIncomeAmmount
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <BonusIncome
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <BonusIncomeAmmount
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <HowPaidOther
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <OtherAmount
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <DebtAmmount
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <LiquidAssets
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    // <AlreadyReceivedGift
    //   page={page}
    //   setPage={setPage}
    //   progrssBar={progrssBar}
    //   setProgressBar={setProgressBar}
    //   formData={formData}
    //   setFormData={setFormData}
    // />,
    <TotalLiquidAssets
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <WantGift
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <GiftAmmount
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <NeedPa
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <Veteran
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <PropertyType
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <PurchasePrice
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <VeteranPass
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <VeteranFail
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <BuyingArea
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <OwnAnotherHome
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <RuralHomePurchasePrice
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <RuralHomePass
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <RuralHomeFail
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <ConventionalPurchasePrice
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <DownPayment
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <LessConventionalPass
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <LessConventionalFail
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <FHAPurchasePrice
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <FHAPass
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <FHAFail
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CategorisedLoan
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <ScheduleMeeeting
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
  ];

  console.log("Form Values", formData)

  return (
    <div className="purchasingSteps-container">
      <div className="progress-bar">
        {
          <div
            style={{
              width:
                page === 0
                  ? "0%"
                  : page === 1
                  ? "3%"
                  : page === 2
                  ? "5%"
                  : page === 3
                  ? "8%"
                  : page === 4
                  ? "10%"
                  : page === 5
                  ? "13%"
                  : page === 6
                  ? "15%"
                  : page === 7
                  ? "18%"
                  : page === 8
                  ? "20%"
                  : page === 9
                  ? "23%"
                  : page === 10
                  ? "25%"
                  : page === 11
                  ? "28%"
                  : page === 12
                  ? "30%"
                  : page === 13
                  ? "33%"
                  : page === 13
                  ? "35%"
                  : page === 14
                  ? "38%"
                  : page === 15
                  ? "40%"
                  : page === 16
                  ? "43%"
                  : page === 17
                  ? "45%"
                  : page === 18
                  ? "48%"
                  : page === 19
                  ? "50%"
                  : page === 20
                  ? "53%"
                  : page === 21
                  ? "55%"
                  : page === 22
                  ? "58%"
                  : page === 23
                  ? "60%"
                  : page === 24
                  ? "63%"
                  : page === 25
                  ? "65%"
                  : page === 26
                  ? "68%"
                  : page === 27
                  ? "70%"
                  : page === 28
                  ? "73%"
                  : page === 29
                  ? "75%"
                  : page === 30
                  ? "78%"
                  : page === 31
                  ? "80%"
                  : page === 32
                  ? "82%"
                  : page === 33
                  ? "84%"
                  : page === 34
                  ? "86%"
                  : page === 35
                  ? "88%"
                  : page === 36
                  ? "90%"
                  : page === 37
                  ? "92%"
                  : page === 38
                  ? "94%"
                  : page === 39
                  ? "95%"
                  : page === 40
                  ? "96%"
                  : page === 41
                  ? "97%"
                  : page === 42
                  ? "98%"
                  : "100%",
            }}
          ></div>
        }
      </div>

      <div className="forms">{componentList[page]}</div>
    </div>
  );
}

export default ScoreAppSteps;