import React from 'react'
import './assetsResultsSteps.scss'

const AssetsResultTwo = ({ assets }) => {
  return (
    <div className='assets-result-component'>
        <div className="assets-result-headings">
            <h1 className="title">Assets Section: Steady Growth – 70-85% Score Range</h1>
            <p className='paragraph'>A 70-85% score in the Assets Section illustrates steady growth. Your current asset management shows promise, with potential areas for further optimization.</p>
        </div>
        <div className="percentage">
          <h5>Assets Score</h5>
          <h2>{assets}%</h2>
          <div className="medium-box">
            Medium
          </div>
        </div>
    </div>
  )
}

export default AssetsResultTwo