import "./purchasingSteps.scss";
import { useState } from "react";
import SignUp from "./purchasingStepsForms/signUp";
import NewHome from "./purchasingStepsForms/newHome";
import CreditScore from "./purchasingStepsForms/creditScore";
import UnderContract from "./purchasingStepsForms/underContract";
import WhereLooking from "./purchasingStepsForms/whereLooking";
import PropertyType from "./purchasingStepsForms/propertyType";
import EstimatedPrice from "./purchasingStepsForms/estimatedPrice";
import DownPayment from "./purchasingStepsForms/downPayment";
import SaveLoan from "./purchasingStepsForms/saveLoan";
import SeeQoute from "./purchasingStepsForms/seeQoute";

function PurchasingSteps() {

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    isMilitry: "",
    selectedCreditScore: "",
    underContract: "",
    cityCode: "",
    selectedPropertyType: "",
    purchasePrice: null,
    downPrice: null,
    isMilitryLoan: "",
    email: "",
    phoneNumber: "",
  });
  const [page, setPage] = useState(0);
  const [progrssBar, setProgressBar] = useState(0);

  const componentList = [
    <SignUp
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <NewHome
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CreditScore
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <UnderContract
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <WhereLooking
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <PropertyType
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <EstimatedPrice
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <DownPayment
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <SaveLoan
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <SeeQoute
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
  ];

  return (
    <div className="purchasingSteps-container">
      <div className="progress-bar">
        {
          <div
            style={{
              width:
                page === 0
                  ? "0%"
                  : page === 1
                  ? "10%"
                  : page === 2
                  ? "20%"
                  : page === 3
                  ? "30%"
                  : page === 4
                  ? "40%"
                  : page === 5
                  ? "50%"
                  : page === 6
                  ? "60%"
                  : page === 7
                  ? "70%"
                  : page === 8
                  ? "80%"
                  : page === 9
                  ? "90%"
                  : "100%",
            }}
          ></div>
        }
      </div>

      <div className="forms">{componentList[page]}</div>
    </div>
  );
}

export default PurchasingSteps;