import React from 'react'
import './getCashStyles.scss'

const GetCash = () => {
  return (
    <div className="getcash-container">
        <h3>EXPRESS LINE OF CREDIT</h3>
        <h1>Get Cash Without Leaving The Couch</h1>
        <h5>Get the funds in as few as 5 days and achieve your goals with our quick and easy process, leaving you free to focus on what matters most.</h5>
        <button className='find-rate-button'>FIND MY RATE</button>
    </div>
  )
}

export default GetCash