import { motion } from "framer-motion";
import FormSelect from "../../reusableComponents/formSelect/formSelect";
import { useState, useEffect } from "react";

const PropertyType = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const [propertyType, setPropertyType] = useState('');
  const [isPropertyTypeValid, setIsPropertyTypeValid] = useState(true);

  const handlePropertyTypeChange = (event) => {
    setPropertyType(event.target.value);
  };

  useEffect(() => {
    setFormData({ ...formData, selectedPropertyType: propertyType });
  }, [propertyType]);

  const propertyTypeOptions = [
    { label: 'Select Property Type', value: 'Select Property Type>' },
    { label: 'Primary Residence', value: 'Primary Residence>' },
    { label: 'Secondary Residence', value: 'Secondary Residence' },
    { label: 'Investment Property', value: 'Investment Property' },
  ];

  const handleNext = () => {
    if (propertyType) {
      setPage(page + 1);
      setProgressBar(1000);
      setIsPropertyTypeValid(true);
    } else {
      setIsPropertyTypeValid(false);
    }
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <h1 className="step-title">your new home ?</h1>
      <div className="form-container">
        <h2 className="form-content-title">What type of property are you buying?</h2>
          <FormSelect
            id="propertyTypeSelect"
            label="PROPERTY TYPE"
            value={propertyType}
            options={propertyTypeOptions}
            onChange={handlePropertyTypeChange}
            error={!isPropertyTypeValid}
          >
          </FormSelect>
          {!isPropertyTypeValid && <p className="error-message">Please select the property type.</p>}
        <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={() => {
              setPage(page - 1);
              setProgressBar(-1000);
            }}
          >
            Previous
          </button>
          <button className="form-button" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default PropertyType;
