import { motion } from "framer-motion";
import ToggleButtons from "../../reusableComponents/toggleButton/toggleButton";
import FormInput from "../../reusableComponents/formInput/formInput";
import { useState } from "react";
import { useEffect } from "react";

const YourProperty = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageTwo, setErrorMessageTwo] = useState('');
  const [toggleState, setToggleState] = useState(false);

  const handleToggleChange = () => {
    setToggleState(!toggleState); 
  };

  useEffect(() => {
    setFormData({ ...formData, isMilitry: toggleState })
  }, [toggleState]);

  const handleSubmit = () => {
    if (formData.address.trim() === '') {
      setErrorMessage('Address is Required.');
    } else {
      setErrorMessage('');
    }

    if (formData.aptUnit.trim() === '') {
      setErrorMessageTwo('APT / Unit is Required.');
    } else {
      setErrorMessageTwo('');
    }

    if (formData.yourCity.trim() === '') {
      setErrorMessageTwo('City is Required.');
    } else {
      setErrorMessageTwo('');
    }

    if (formData.yourState.trim() === '') {
      setErrorMessageTwo('State is Required.');
    } else {
      setErrorMessageTwo('');
    }
    
    if (formData.yourZipCode.trim() === '') {
      setErrorMessageTwo('Zip Code is Required.');
    } else {
      setErrorMessageTwo('');
    }

    if (formData.address.trim() !== ''
        && formData.aptUnit.trim() !== ''
        && formData.yourCity.trim() !== ''
        && formData.yourState.trim() !== ''
        && formData.yourZipCode.trim() !== '') {
      setPage(page + 1);
      setProgressBar(1000);
    }
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">Your property</div>
      <div className="form-container">
        <h2 className="form-content-title">What's your address?</h2>
        <div className="form-fields">
          <FormInput
            label="ADDRESS *"
            value={formData.address}
            onChange={(e) => setFormData({ ...formData, address: e.target.value })}
            placeholder={"Enter a location"}
            required
            error={errorMessage}
          />
          <FormInput
            label="APT / UNIT *"
            value={formData.aptUnit}
            onChange={(e) => setFormData({ ...formData, aptUnit: e.target.value })}
            required
            error={errorMessageTwo}
          />
        </div>
        <div className="form-fields">
          <FormInput
            label="CITY *"
            value={formData.yourCity}
            onChange={(e) => setFormData({ ...formData, yourCity: e.target.value })}
            placeholder={"Greenville"}
            required
            error={errorMessage}
          />
          <FormInput
            label="STATE *"
            value={formData.yourState}
            onChange={(e) => setFormData({ ...formData, yourState: e.target.value })}
            placeholder={"New York"}
            required
            error={errorMessageTwo}
          />
        </div>
        <div className="form-fields">
          <FormInput
            label="ZIP CODE *"
            value={formData.yourZipCode}
            onChange={(e) => setFormData({ ...formData, yourZipCode: e.target.value })}
            type={"number"}
            required
            error={errorMessage}
          />
        </div>
        <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={() => {
              setPage(page - 1);
              setProgressBar(-1000);
            }}
          >
            Previous
          </button>
          <button className="form-button" onClick={handleSubmit}>
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default YourProperty;
