import React from "react";
import "./helocAnalyticStyles.scss";

import { review } from "../../../common/images";

export const HelocAnalytics = () => {
  return (
    <div className="main-heloc"> 
        <p className="sub-font">WE MAKE IT SIMPLE BECAUSE IT SHOULD BE</p>
        <div className="valuebox">
          <div className="a-box-small">
            <div className="pt-3 pb-2">
              <img
                src={review}
                className="round-box"
                height="90px"
                width="90px"
              />
              <div className="card-body">
                <div className="card-para">
                  <p>apply now with our 100% online application in a matter of minutes</p>
                </div>
              </div>
            </div>
          </div>
          <div className="a-box-small">
            <div className="pt-3 pb-2">
              <img
                src={review}
                className="round-box"
                height="90px"
                width="90px"
              />
              <div className="card-body">
                <div className="card-para">
                  <p>credit scores as low as 620</p>
                </div>
              </div>
            </div>
          </div>
          <div className="a-box-small">
            <div className="pt-3 pb-2">
              <img
                src={review}
                className="round-box"
                height="90px"
                width="90px"
              />
              <div className="card-body">
                <div className="card-para">
                  <p>available for primary, second, and investment homes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};
