import React from 'react'
import './creditResultsSteps.scss'

const CreditResultThree = ({ credit }) => {
  return (
    <div className='credit-result-component'>
        <div className="credit-result-headings">
            <h1 className="title">Credit Section: A Path to Improvement – 0-69% Score Range</h1>
            <p className='paragraph'>Your score in the Credit Section falls within the 0-69% range, indicating areas where focused attention and improvement might be needed.</p>
        </div>
        <div className="percentage">
          <h5>Credit Score</h5>
          <h2>{credit}%</h2>
          <div className="low-box">
            Low
          </div>
        </div>
    </div>
  )
}

export default CreditResultThree