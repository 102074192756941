import { useState, useEffect } from "react";
import TotalResultOne from "./totalResultsOne";
import TotalResultTwo from "./totalResultsTwo";
import TotalResultThree from "./totalResultsThree";

function TotalResultsSteps({ credit, income, debtResult, assets }) {
  const totalValue = (credit + income + debtResult + assets) / 4;
  const totalPercentage = parseFloat(totalValue.toFixed(2));
  
  let pageToSet;
  
  if (totalPercentage > 85) {
    pageToSet = 0;
  } else if (totalPercentage >= 70) {
    pageToSet = 1;
  } else {
    pageToSet = 2;
  }

  let creditColor;
  
  if (credit > 85) {
    creditColor = '#ed6f2d';
  } else if (credit >= 70) {
    creditColor = "#f9d448";
  } else {
    creditColor = "#0c1137";
  }

  let incomeColor;
  
  if (income > 85) {
    incomeColor = '#ed6f2d';
  } else if (income >= 70) {
    incomeColor = "#f9d448";
  } else {
    incomeColor = "#0c1137";
  }

  let debtColor;
  
  if (debtResult > 85) {
    debtColor = '#ed6f2d';
  } else if (debtResult >= 70) {
    debtColor = "#f9d448";
  } else {
    debtColor = "#0c1137";
  }

  let assetsColor;
  
  if (assets > 85) {
    assetsColor = '#ed6f2d';
  } else if (assets >= 70) {
    assetsColor = "#f9d448";
  } else {
    assetsColor = "#0c1137";
  }

  const componentList = [
    <TotalResultOne credit={credit} income={income} debtResult={debtResult} assets={assets} creditColor={creditColor} incomeColor={incomeColor} debtColor={debtColor} assetsColor={assetsColor} />,
    <TotalResultTwo credit={credit} income={income} debtResult={debtResult} assets={assets} creditColor={creditColor} incomeColor={incomeColor} debtColor={debtColor} assetsColor={assetsColor} />,
    <TotalResultThree credit={credit} income={income} debtResult={debtResult} assets={assets} creditColor={creditColor} incomeColor={incomeColor} debtColor={debtColor} assetsColor={assetsColor} />
  ];

  const [page, setPage] = useState(pageToSet);

  useEffect(() => {
    setPage(pageToSet);
  }, [pageToSet]);

  return (
    <div className="forms">{componentList[page]}</div>
  );
}

export default TotalResultsSteps;
