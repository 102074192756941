import "./purchasingSteps.scss";
import { useState } from "react";
import PersonalInformation from "./applyNowStepsForms/personalInformation";
import Veteran from "./applyNowStepsForms/veteran";
import PriceRange from "./applyNowStepsForms/priceRange";
import PropertyType from "./applyNowStepsForms/propertyType";
import UseNewHome from "./applyNowStepsForms/useNewHome";
import PurchasingPlan from "./applyNowStepsForms/propertyBuying";
import OwnHome from "./applyNowStepsForms/ownHome";
import CurrentlyMonthlyPayment from "./applyNowStepsForms/currentMonthlyPayment";
import SellCurrentHome from "./applyNowStepsForms/sellCurrentHome";
import DownPayment from "./applyNowStepsForms/downPayment";
import EmploymentStatus from "./applyNowStepsForms/employmentStatus";
import AnnualIncome from "./applyNowStepsForms/annualIncome";
import MaritalStatus from "./applyNowStepsForms/maritalStatus";
import MortgageLoan from "./applyNowStepsForms/mortgageLoan";
import BankRuptcy from "./applyNowStepsForms/bankRuptcy ";
import CreditScore from "./applyNowStepsForms/creditScore";
import HearAbout from "./applyNowStepsForms/hearAbout";
import IdentifiedProperty from "./applyNowStepsForms/identifiedProperty";
import IdentifiedPropertyAddress from "./applyNowStepsForms/identifiedPropertyAddress";
import SubmitForm from "./applyNowStepsForms/submitForm";
import LivedDurationCurrentAddress from "./applyNowStepsForms/livedDurationCurrentAddress";
import FullName from "./applyNowStepsForms/fullName";
import Email from "./applyNowStepsForms/email";
import CoBorrower from "./applyNowStepsForms/coBorrower";
import CoBorrowerName from "./applyNowStepsForms/coBorrowerName";
import CoBorrowerEmail from "./applyNowStepsForms/coBorrowerEmail";
import CoBorrowerPhone from "./applyNowStepsForms/coBorrowerPhone";
import PhoneNumber from "./applyNowStepsForms/phoneNumber";
import TimePreferences from "./applyNowStepsForms/timePreferences ";
import PreferredMethod from "./applyNowStepsForms/preferredMethod";
import StreetAddress from "./applyNowStepsForms/streetAddress";
// import BranchServices from "./applyNowStepsForms/branchServices";

function ApplyNowSteps() {

  const [formData, setFormData] = useState({
    startQuote: "",
    veteran: "",
    // branchServices: "",
    priceRange: "",
    propertyType: "",
    useNewHome: "",
    PurchasingPlan: "",
    ownHome: "",
    currentlyMonthlyPayment: "",
    sellCurrentHome: "",
    employmentStatus: "",
    maritalStatus: "",
    mortgageLoan: "",
    bankRuptcy : "",
    creditScore: "",
    hearAbout: "",
    identifiedProperty: "",
    identifiedPropertyAddress: "",
    streetAddress: "",
    livedDuration: "",
    fullName: "",
    email: "",
    coBorrower: "",
    coBorrowerName: "",
    coBorrowerEmail: "",
    coBorrowerPhone: "",
    preferredMethod: "",
    timePreferences: "",
    downPayment: "",
    annualIncome: "",
    dateTime: "",
  });
  const [page, setPage] = useState(0);
  const [progrssBar, setProgressBar] = useState(0);

  const componentList = [
    <PersonalInformation
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <Veteran
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    // <BranchServices
    //   page={page}
    //   setPage={setPage}
    //   progrssBar={progrssBar}
    //   setProgressBar={setProgressBar}
    //   formData={formData}
    //   setFormData={setFormData}
    // />,
    <PriceRange
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <PropertyType
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <UseNewHome
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <PurchasingPlan
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <OwnHome
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CurrentlyMonthlyPayment
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <SellCurrentHome
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <DownPayment
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <EmploymentStatus
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <AnnualIncome
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <MaritalStatus
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <MortgageLoan
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <BankRuptcy
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CreditScore
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <HearAbout
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <IdentifiedProperty
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <IdentifiedPropertyAddress
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <StreetAddress
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <LivedDurationCurrentAddress
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <FullName
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <Email
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CoBorrower
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CoBorrowerName
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CoBorrowerEmail
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <CoBorrowerPhone
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <PhoneNumber
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <PreferredMethod
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <TimePreferences
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
    <SubmitForm
      page={page}
      setPage={setPage}
      progrssBar={progrssBar}
      setProgressBar={setProgressBar}
      formData={formData}
      setFormData={setFormData}
    />,
  ];

  console.log("radio", formData)

  return (
    <div className="purchasingSteps-container">
      <div className="progress-bar">
        {
          <div
            style={{
              width:
                page === 0
                  ? "0%"
                  : page === 1
                  ? "3%"
                  : page === 2
                  ? "6%"
                  : page === 3
                  ? "9%"
                  : page === 4
                  ? "12%"
                  : page === 5
                  ? "15%"
                  : page === 6
                  ? "18%"
                  : page === 7
                  ? "21%"
                  : page === 8
                  ? "24%"
                  : page === 9
                  ? "27%"
                  : page === 10
                  ? "30%"
                  : page === 11
                  ? "33%"
                  : page === 12
                  ? "36%"
                  : page === 13
                  ? "39%"
                  : page === 14
                  ? "42%"
                  : page === 15
                  ? "45%"
                  : page === 16
                  ? "48%"
                  : page === 17
                  ? "51%"
                  : page === 18
                  ? "54%"
                  : page === 19
                  ? "57%"
                  : page === 20
                  ? "60%"
                  : page === 21
                  ? "63%"
                  : page === 22
                  ? "66%"
                  : page === 23
                  ? "69%"
                  : page === 24
                  ? "72%"
                  : page === 25
                  ? "75%"
                  : page === 26
                  ? "78%"
                  : page === 27
                  ? "81%"
                  : page === 28
                  ? "84%"
                  : page === 29
                  ? "95%"
                  : "100%",
            }}
          ></div>
        }
      </div>

      <div className="forms">{componentList[page]}</div>
    </div>
  );
}

export default ApplyNowSteps;