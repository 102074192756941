import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../index.scss'
import AdminHeader from '../adminHeader';
import { useNavigate } from 'react-router-dom';

const AdminLoginForm = () => {
    
  const navigate = useNavigate();

  const handleSignUpClick = () => {
    navigate('/adminSignUp');
  };

    const initialValues = {
        email: '',
        password: '',
      };
    
      const validationSchema = Yup.object({
        email: Yup.string().email('Invalid User Name format').required('User Name is required'),
        password: Yup.string().required('Password is required'),
      });
    
      const handleSubmit = (values) => {
        console.log('email:', values.email);
        console.log('Password:', values.password);
        navigate('/adminPanel')
      };
    
      return (
        <div className="form-page">
        <AdminHeader />
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
            <div className="form-container">
                <h1>Loan Officer Login</h1>
                <Form className='form'>
                    <div className='form-field'>
                    <label htmlFor="email">User Name *</label>
                    <Field className='form-input-field' 
                        type="text" 
                        id="email" 
                        name="email"
                        placeholder="User@abc.com" 
                    />
                    <ErrorMessage className='error-message' name="email" component="div" />
                    </div>
                    <div className='form-field'>
                    <label htmlFor="password">Password *</label>
                    <Field className='form-input-field' 
                        type="password" 
                        id="password" 
                        name="password"
                        placeholder="Enter Password" 
                    />
                    <ErrorMessage className='error-message' name="password" component="div" />
                    </div>
                    <button className='login-button' type="submit">Log In</button>
                    <button className='sign-up-button' onClick={handleSignUpClick}>Don't have an account? <span>Sign Up</span></button>
                </Form>
            </div>
            </Formik>
        </div>
      );
    };
export default AdminLoginForm