import { motion } from "framer-motion";
import FormInput from "../../reusableComponents/formInput/formInput";
import { useState } from "react";

const WhereLooking = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = () => {
    if (formData.cityCode.trim() === '') {
      setErrorMessage('City Code is Required.');
    } else {
      setErrorMessage('');
      setPage(page + 1);
      setProgressBar(1000);
    }
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <h1 className="step-title">your new home ?</h1>
      <div className="form-container">
        <h2 className="form-content-title">Where are you looking?</h2>
        <div className="input-field">
          <FormInput
            label="CITY OR ZIP CODE *"
            value={formData.cityCode}
            onChange={(e) => setFormData({ ...formData, cityCode: e.target.value })}
            required
            error={errorMessage}
          />
        </div>
        <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={() => {
              setPage(page - 1);
              setProgressBar(-1000);
            }}
          >
            Previous
          </button>
          <button className="form-button" onClick={handleSubmit}>
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default WhereLooking;
