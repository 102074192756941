import { motion } from "framer-motion";
import { useEffect, useState } from "react";

  const DebtAmmount = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const debtSession = sessionStorage.getItem('debtAmount') || 0;
    const initialValue = parseInt(debtSession, 10);  
    const [debtPrice, setDebtPrice] = useState(initialValue);
    const [disable, setDisable] = useState(false);
  
    const handleIncrement = () => {
      setDebtPrice(parseFloat(debtPrice) + 1000);
    }
    const handlePurchaseChange = (event) => {
      setDebtPrice(event.target.value);
      setDisable(true);
    };
    useEffect(() => {
      sessionStorage.setItem('debtAmount', debtPrice);
      setFormData({ ...formData, debtAmount: debtPrice })
    }, [debtPrice]);

  const handlePrevius = () => {
    
    switch (true) {
      case formData.howPaidArray.includes("Other"):
      setPage(page - 1);
      setProgressBar(progressBar - 1000);
      break;
      case formData.howPaidArray.includes("Bonus") && formData.bonusIncome === "Yes":
      setPage(page - 3);
      setProgressBar(progressBar - 3000);
      break;
      case formData.howPaidArray.includes("Bonus"):
      setPage(page - 4);
      setProgressBar(progressBar - 4000);
      break;
      case formData.howPaidArray.includes("Commission") && formData.commissionIncome === "Yes":
      setPage(page - 5);
      setProgressBar(progressBar - 5000);
      break;
      case formData.howPaidArray.includes("Commission"):
      setPage(page - 6);
      setProgressBar(progressBar - 6000);
      break;
      case formData.howPaidArray.includes("Salary"):
      setPage(page - 7);
      setProgressBar(progressBar - 7000);
      break;
      case formData.howPaidArray.includes("Overtime") && formData.overtimeIncome === "Yes":
      setPage(page - 8);
      setProgressBar(progressBar - 8000);
      break;
      case formData.howPaidArray.includes("Overtime"):
      setPage(page - 9);
      setProgressBar(progressBar - 9000);
      break;
      case formData.howPaidArray.includes("Hourly"):
      setPage(page - 10);
      setProgressBar(progressBar - 10000);
      break;
    default:
      setPage(6);
      setProgressBar(6000);
      break;
  }
};
    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        {/* <h1 className="step-title">Debt Amount</h1> */}
        <div className="form-container">
        <h2 className="form-content-title">Enter the Sum of all your MONTHLY Debts *</h2>
        <p>Total Monthly Car Payments + Installment Loans + Minimum Credit Card Payments + Student Loans (Even if deferred). <br />(Do not include insurance or utilities)</p>
        <div className="estimated-field">
          <label>Enter Total Monthly Debts</label>
          <div className="estimated-price">
          <input placeholder={`$ ${debtPrice}`} onChange={handlePurchaseChange} type="number"/>
        <div className="price-counter">
          <button disabled={disable} className="minus-sign" onClick={() => setDebtPrice(debtPrice - 1000)}>-</button>
          <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={handlePrevius}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={() => {
            setPage(page + 1);
            setProgressBar(1000);
          }}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default DebtAmmount;