import React from 'react'
import CalculationSteps from '../../components/calculatorSteps/calculationSteps'
import MainLayout from '../../components/layout/layout'

const Calculator = () => {
  return (
    <MainLayout>
      <CalculationSteps />
    </MainLayout>
  )
}

export default Calculator