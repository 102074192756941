import React from 'react'
import './freeQuotes.scss'
import { house, dollar, arrow } from "../../../common/images";
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const FreeQuotes = () => {
  return (
    <div className='free-quotes-container'>
    <h1 className='hero-title'>Borrow Smarter, Faster, Better</h1>
    <h1 className='hero-title'>Your Mortgage Journey, Reimagined</h1>
    <p className='hero-headings'>At our core, we believe in making loans refreshingly simple.We understand that the process can be overwhelming, so we've designed our system to be hassle-free and straightforward. From prequalification to application to approval, we've here to simplify every step, ensuring you have a smooth and stress-free borrowing experience. Because getting a loan should be easy and straightforward, just the way you want it to be.</p>
        <div className="free-quotes-cards">
          <Link to='/purchasingProcess'>             
          <div className="free-quotes-card">
                  <div className="card-right">
                      <h3 className='card-title'>Purchase</h3>
                      <div className="free-rate">
                          <h5 className='card-heading'>FREE RATE QUOTE</h5>
                          <img
                              src={arrow}
                              alt='arrow'
                              className='arrow'
                          />
                      </div>
                  </div>
                  <div className="card-left">
                    <img
                      src={house}
                      alt='icon'
                      className='card-icon'
                    />
                  </div>
              
                  <div className="free-quotes-card-hover">
                  <div className="card-right">
                      <h3 className='card-title'>Purchase</h3>
                      <p className='card-paragraph'>Simplifying the process and helping purchasers lock in a low interest rate with no junk fees.</p>
                      <div className="free-rate">
                          <h5 className='card-heading'>FREE RATE QUOTE</h5>
                          <FaArrowRight className='hover-arrow' />
                      </div>
                  </div>
                  <div className="card-left">
                    <img
                      src={house}
                      alt='icon'
                      className='card-icon'
                    />
                  </div>
              </div>
              </div>
          </Link>
          <Link to='/refinanceProcess'>              
          <div className="free-quotes-card">
                  <div className="card-right">
                      <h3 className='card-title'>Refinance</h3>
                      <div className="free-rate">
                          <h5 className='card-heading'>FREE RATE QUOTE</h5>
                          <img
                              src={arrow}
                              alt='arrow'
                              className='arrow'
                          />
                      </div>
                  </div>
                  <div className="card-left">
                    <img
                      src={dollar}
                      alt='icon'
                      className='card-icon'
                    />
                  </div>
              
                  <div className="free-quotes-card-hover">
                  <div className="card-right">
                      <h3 className='card-title'>Refinance</h3>
                      <p className='card-paragraph'>a new, better loan that pays off your old mortgage loan</p>
                      <div className="free-rate">
                          <h5 className='card-heading'>FREE RATE QUOTE</h5>
                          <FaArrowRight className='hover-arrow' />
                      </div>
                  </div>
                  <div className="card-left">
                    <img
                      src={dollar}
                      alt='icon'
                      className='card-icon'
                    />
                  </div>
              </div>
              </div>
          </Link>
        </div>
    </div>
  )
}

export default FreeQuotes