import { motion } from "framer-motion";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const BonusIncome = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required('Please select an option'),
  });

  const handleNext = (values) => {
    setFormData({ ...formData, bonusIncome: values.selectedOption });
    if (values.selectedOption == "Yes"){
    setPage(page + 1);
    setProgressBar(1000);
    }
    else{
      setPage(18);
      setProgressBar(18000);
    }
  };
  const handlePrevius = () => {
    
    switch (true) {
      case formData.howPaidArray.includes("Commission") && formData.commissionIncome=="Yes":
      setPage(page - 1);
      setProgressBar(progressBar - 1000);
      break;
      case formData.howPaidArray.includes("Commission"):
      setPage(page - 2);
      setProgressBar(progressBar - 2000);
      break;
      case formData.howPaidArray.includes("Salary"):
      setPage(page - 3);
      setProgressBar(progressBar - 3000);
      break;
      case formData.howPaidArray.includes("Overtime"):
      setPage(page - 4);
      setProgressBar(progressBar - 4000);
      break;
      case formData.howPaidArray.includes("Hourly"):
      setPage(page - 7);
      setProgressBar(progressBar - 7000);
      break;
    default:
      setPage(6);
      setProgressBar(6000);
      break;
  }
};
  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      {/* <div className="step-title">Bonus Income</div> */}
      <div className="form-container">
        <h2 className="form-content-title">Do you have consistent bonus income for at least the last 2 years? *</h2>
        <p className="text">(If No, we cannot count your bonus income.)</p>
        <Formik
          initialValues={{
            selectedOption: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, touched }) => (
            <Form>
              <div className="radio-options">
                <label className="radio-option" onClick={() => setFormData({ ...formData, veteranValue: 5 })}>
                  <Field type="radio" name="selectedOption" value="Yes" />
                  Yes
                </label>
                <label className="radio-option" onClick={() => setFormData({ ...formData, veteranValue: 5 })}> 
                  <Field type="radio" name="selectedOption" value="No" />
                  No
                </label>
              {touched.selectedOption && !values.selectedOption && (
                <div className="error-message">Please select an option</div>
              )}
              </div>
          <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={handlePrevius}
          >
            Previous
          </button>
                <button className="form-button" type="submit" disabled={!values.selectedOption && touched.selectedOption}>
                  Next
                </button>
        </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};

export default BonusIncome;
