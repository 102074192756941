import { useState, useEffect } from "react";
import IncomeResultOne from "./incomeResultsOne";
import IncomeResultTwo from "./incomeResultsTwo";
import IncomeResultThree from "./incomeResultsThree";

function IncomeSteps({ income }) {
  
  let pageToSet;
  
  if (income > 85) {
    pageToSet = 0;
  } else if (income >= 70) {
    pageToSet = 1;
  } else {
    pageToSet = 2;
  }

  const componentList = [
    <IncomeResultOne income={income} />,
    <IncomeResultTwo income={income} />,
    <IncomeResultThree income={income} />
  ];

  const [page, setPage] = useState(pageToSet);

  useEffect(() => {
    setPage(pageToSet);
  }, [pageToSet]);

  return (
    <div className="forms">{componentList[page]}</div>
  );
}

export default IncomeSteps;
