import axios from 'axios';
import { parseStringPromise } from 'xml2js';

const api = axios.create({
  baseURL: 'http://18.220.59.147/',
});

export const getQoutes = async (buttonLabel) => {
  try {
    console.log("getQuotes", buttonLabel);
    const response = await api.get(
      `https://thirdparty.mortech-inc.com/mpg/servlet/mpgThirdPartyServlet`,
      {
        params: {
          request_id: 1,
          customerId: 'Tyler',
          thirdPartyName: 'TylerLemkau',
          emailAddress: 'tylerle@zillowgroup.com',
          loan_amount: 200000,
          propertyState: 'CA',
          licenseKey: 'MortechTyler',
          loanProduct1: buttonLabel,
          loanProduct2: '25 year fixed',
          loanProduct3: '20 year fixed',
          loanProduct4: '15 year fixed',
          loanProduct5: '10 year fixed',
        },
      }
    );
    const xmlData = response.data;
    const jsonData = await parseStringPromise(xmlData, { explicitArray: false });

    console.log("Response", jsonData);
    return jsonData;
  } catch (error) {
    console.log("Error", error);
    throw error;
  }
};
