import React from "react";
import "./analyticStyles.scss";
import { allTexts } from "../../common/all-text";
import { PurchaseCard } from "../../components";
import { Card } from "../../components";
import { BsStarFill } from "react-icons/bs";

import { revenue, time, review, clock, clockOne, clockTwo, calculator } from "../../common/images";

export const Analytics = () => { 
  return (
    <div className="main-analytic">
        {/* <p className="sub-font">{allTexts.paragraphs.loans}</p> */}
        <div className="valuebox">
          <div className="a-box-small">
            <div className="pt-3 pb-2">
              <img
                src={review}
                className="round-box"
                height="90px"
                width="90px"
              />
              <div className="card-body">
                <div className="color-count">
                  <div className="review">
                    LIVE RATES
                  </div>
                </div>
                <div className="color-box">
                  <p>Discover Payment Possibilities</p>
                </div>

                <p className="card-c">SEE FIRST HAND THAT <br /> WHOLESALES RATES ARE BETTER</p>
              </div>
            </div>
          </div>
          <div className="a-box-small">
            <div className="pt-3 pb-2">
              <img
                src={calculator}
                className="round-box"
                height="90px"
                width="90px"
              />
              <div className="card-body">
                <div className="color-count">
                  <div className="review">
                    MORTGAGE CALCULATOR
                  </div>
                </div>
                <div className="color-box">
                  <p>Discover Payment Possibilities</p>
                </div>

                <p className="card-c">EXPERIMENT IN PRIVATE</p>
              </div>
            </div>
          </div>
          <PurchaseCard
            // num={41.2}
            // suf=" + BILLION"
            // dec={1}
            // pre="$ "
            num={5}
            suf=" MINUTES"
            icon={clockOne}
            title={allTexts.placeHolders.avgLoan}
            paragraph={allTexts.paragraphs.year}
          />
          <PurchaseCard
            num={20}
            icon={clockTwo}
            suf=" MINUTES"
            title={allTexts.placeHolders.time}
            paragraph={allTexts.paragraphs.application}
          />

          {/* <PurchaseCard
            num={2}
            dec={3}
            decValue=","
            icon={review}
            suf=" CUSTOMERS"
            title={allTexts.placeHolders.avgReview}
            paragraph={allTexts.paragraphs.satisfaction}
          /> */}

        </div>
    </div>
  );
};
