import React from 'react'
import './whyHelocStyles.scss'

const WhyHeloc = () => {
  return (
    <div className="whyheloc-container">
        <h3>WHY HELOC?</h3>
        <h1 className='title'>Get funding fast with our 100% online application that takes just minutes.</h1>
        <h5>No need to wait for an in-person appraisal. Move forward with life and get the funds you need in just a few days.</h5>
        <button className='find-rate-button'>GET APPROVED IN 24 HOURS</button>
        <div className="heloc-list">
          <div className="item">
            <h1>01.</h1>
            <h4>Borrow up to $500,000 using your home equity to meet your financial needs.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>02.</h1>
            <h4>Loan-to-value (LTV): 90%</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>03.</h1>
            <h4>Ability to fix in the rate on a portion of the line of credit</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>04.</h1>
            <h4>No need to wait for an in-person appraisal.</h4>
          </div>
          <div className="divider"></div>
        </div>
    </div>
  )
}

export default WhyHeloc