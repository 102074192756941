import { motion } from "framer-motion";
import { useEffect, useState } from "react";

  const BonusIncomeAmount = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const bonusAmounts = sessionStorage.getItem('bonusAmount') || 0;
    const initialValue = parseInt(bonusAmounts, 10); 
    const [bonusIncome, setBonusIncomeAmount] = useState(initialValue);
    const [disable, setDisable] = useState(false);
  
    const handleIncrement = () => {
      setBonusIncomeAmount(parseFloat(bonusIncome) + 1000);
    }
    const handlePurchaseChange = (event) => {
      setBonusIncomeAmount(event.target.value);
      setDisable(true);
    };
    useEffect(() => {
      sessionStorage.setItem('bonusAmount', bonusIncome);
      setFormData({ ...formData, bonusIncomeAmount: bonusIncome })
    }, [bonusIncome]);

    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        {/* <h1 className="step-title">Bonus Income</h1> */}
        <div className="form-container">
        <h2 className="form-content-title">What is your annual bonus income? *</h2>
        <div className="estimated-field">
          <label>Enter Annual Bonus Income</label>
          <div className="estimated-price">
          <input placeholder={`$ ${bonusIncome}`} onChange={handlePurchaseChange} type="number"/>
        <div className="price-counter">
          <button disabled={disable} className="minus-sign" onClick={() => setBonusIncomeAmount(bonusIncome - 1000)}>-</button>
          <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 1);
            setProgressBar(-1000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={() => {
            setPage(18);
            setProgressBar(18000);
          }}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default BonusIncomeAmount;