import React from 'react'
import './incomeResultsSteps.scss'

const IncomeResultOne = ({ income }) => {
  return (
    <div className='income-result-component'>
        <div className="income-result-headings">
            <h1 className="title">Income Section: Financially Flourishing – 86-100% Score Range</h1>
            <p className='paragraph'>Scoring 86-100% in the Income Section highlights your stable and promising income situation. Your answers demonstrate a strong understanding of your earnings and their relation to mortgage eligibility.</p>
        </div>
        <div className="percentage">
          <h5>Income Score</h5>
          <h2>{income}%</h2>
          <div className="high-box">
            High
          </div>
        </div>
    </div>
  )
}

export default IncomeResultOne