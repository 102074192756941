import React, { useState } from 'react'
import FilterButtons from './filterbar'
import PurchasingProcessTable from './purchasingProcessTable'
import './purchasingProcessResults.scss'
import { useLocation } from "react-router-dom";

const PurchasingProcessResults = () => {
  const location = useLocation();
  // const data = location.state;
  // console.log("response", data);
  const [data, setData] = useState(location.state);
  const [isLoading, setIsLoading] = useState(true);
  return (
    <div className="purchasing-process-results">
        <FilterButtons data={data} setData={setData} isLoading={isLoading} setIsLoading={setIsLoading}/>
        <PurchasingProcessTable data={data} isLoading={isLoading} />
    </div>
  )
}

export default PurchasingProcessResults