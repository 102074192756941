import React from "react";
import { MainLayout } from "../../components";
import GetCash from "../../components/heloc/getCash/getCash";
import { HelocAnalytics } from "../../components/heloc/helocAnalytic";
import WhyHeloc from "../../components/heloc/whyHeloc/whyHeloc";

const HELOC = () => {
  return (
    <MainLayout>
      <GetCash />
      <HelocAnalytics />
      <WhyHeloc />
    </MainLayout>
  );
};

export default HELOC;
