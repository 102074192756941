import React from 'react'
import '../index.scss'
import { Inflationlogo } from "../../../common/images";
import { useNavigate } from 'react-router-dom';

const AdminHeader = () => {
    
    const navigate = useNavigate();
  
    const handleLoginClick = () => {
      navigate('/adminLogin');
    };
  return (
    <div className="admin-header">
    <img src={Inflationlogo} alt="logo" />
    <button className='header-login-button' onClick={handleLoginClick}>Login</button>
    </div>
  )
}

export default AdminHeader