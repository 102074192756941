import React from "react";
import "./cardStyle.scss";
import { Link } from "react-router-dom";
import { allTexts } from "../../common/all-text";
import { arrow } from "../../common/images";

// eslint-disable-next-line react/prop-types
function Card({ title, info, img, icon, topIcon, subtittle, paragraph, link }) {
  // console.log("work")

  return (
    <div className="example-2 card-1">
      <div className="wrapper">
        <div className="pt-3 pb-2">
          <div className="img_holder">
            <img src={icon} className="imageBox" height="60px" width="60px" />
          </div>
          <div className="card-body">
            <div className="card-holder">
              <p>{title}</p>
              <p className="card-text"></p>
            </div>
            <Link>
              <a href={"#"} class="">
                <div className="text-style-a">
                  {allTexts.navLinks.free}
                  <img
                    src={arrow}
                    className="arrow"
                    height="60px"
                    width="60px"
                  />
                </div>
              </a>
            </Link>
          </div>
        </div>
    <Link to={link}>
        <div className="data">
          <div className="content">
            <div className="align">
              <Link
                to="home"
                className="hash"
                smooth={true}
                offset={50}
                duration={600}
              >
                <div className="img_holder_inner">
                  <img
                    src={icon}
                    className="imageBox_inner"
                    height="60px"
                    width="60px"
                  />{" "}
                </div>
              </Link>
              <div className="card-holder-b">
                <p>{title}</p>
              </div>
              <div className="block-flex">
                <p>{paragraph}</p>
              </div>
                <a href={"#"} class="button">
                  {allTexts.navLinks.free}
                </a>
            </div>
          </div>
        </div>
        </Link>
      </div>
    </div>
  );
}

export default Card;
