import { motion } from "framer-motion";
import { useEffect, useState } from "react";

  const CommissionIncomeAmount = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const commissionRateSession = sessionStorage.getItem('commissionAmount') || 0;
    const initialValue = parseInt(commissionRateSession, 10); 
    const [commissionIncome, setCommissionIncomeAmount] = useState(initialValue);
    const [disable, setDisable] = useState(false);
  
    const handleIncrement = () => {
      setCommissionIncomeAmount(parseFloat(commissionIncome) + 1000);
    }
    const handlePurchaseChange = (event) => {
      setCommissionIncomeAmount(event.target.value);
      setDisable(true);
    };
    useEffect(() => {
      sessionStorage.setItem('commissionAmount', commissionIncome);
      setFormData({ ...formData, commissionIncomeAmount: commissionIncome })
    }, [commissionIncome]);

    const handleNext = () => {
      switch (true) {
          case formData.howPaidArray.includes("Bonus"):
          setPage(14);
          setProgressBar(14000);
          break;
        default:
          setPage(18);
          setProgressBar(18000);
          break;
      }
    };

    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        {/* <h1 className="step-title">Commission Income</h1> */}
        <div className="form-container">
        <h2 className="form-content-title">What is your annual commission income? *</h2>
        <div className="estimated-field">
          <label>Enter Annual Commision Income</label>
          <div className="estimated-price">
          <input placeholder={`$ ${commissionIncome}`} onChange={handlePurchaseChange} type="number"/>
        <div className="price-counter">
          <button disabled={disable} className="minus-sign" onClick={() => setCommissionIncomeAmount(commissionIncome - 1000)}>-</button>
          <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 1);
            setProgressBar(-1000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default CommissionIncomeAmount;