import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";

const LessCreditFail = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const data = formData;
  const navigate = useNavigate();
  const onExit = () => {
    navigate('/prequalifyResults', { state: data });
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      {/* <div className="step-title">Requirement?</div> */}
      <div className="form-container">
        {/* <h2 className="form-content-title">Loan Officer Appointment</h2> */}
              <p className="text">We are sorry, we do not have any lenders that will loan below 580 credit score with derogatory credit history.</p>
              <div className="form-buttons">
                <button
                  className="form-button-differ"
                  onClick={() => {
                    setPage(page - 3);
                    setProgressBar(setProgressBar - 3000); 
                  }}
                >
                  Previous
                </button>
                <button
                  className="form-button"
                  onClick={onExit}
                >
                  See Results
                </button>
                <button className="form-button-large"
                  onClick={() => {
                    setPage(42);
                    setProgressBar(42000); 
                    setFormData({ ...formData, derogatoryFailure: "true" })
                  }}>
                  Schedule Appointment
                </button>
              </div>
      </div>
    </motion.div>
  );
};

export default LessCreditFail;
