import React from 'react'
import './loanOptionsStyles.scss'

const UsdaLoan = () => {
  return (
    <div className="loanOptionsTypes-container" id="usda-loan">
        <h3>USDA LOAN</h3>
        <h1 className='title'>Usda Loan</h1>
        <h5>Excellent option for borrowers in rural areas or with high-acreage properties.</h5>
        <div className="advantages-list">
          <h3 className='advantages-title'>ADVANTAGES:</h3>
          <div className="item">
            <h1>01.</h1>
            <h4>Low monthly mortgage insurance.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>02.</h1>
            <h4>Allows up to 100% financing on purchases.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>03.</h1>
            <h4>Flexible credit guidelines.</h4>
          </div>
          <div className="divider"></div>
        </div>
        <button className='find-rate-button'>APPLY NOW</button>
    </div>
  )
}

export default UsdaLoan