import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox'; // Import the Checkbox component
import { FiEdit, FiDelete } from 'react-icons/fi';
import { RiDeleteBinLine } from 'react-icons/ri';

function createData(id, firstName, lastName, age, address) {
  return { id, firstName, lastName, age, address };
}

const rows = [
  createData('1', 'Jon', 'Snow', 24, 'St. Robert, MO 65584-5678'),
  createData('2', 'Jon', 'Snow', 24, 'St. Robert, MO 65584-5678'),
  createData('3', 'Jon', 'Snow', 24, 'St. Robert, MO 65584-5678'),
  createData('4', 'Jon', 'Snow', 24, 'St. Robert, MO 65584-5678'),
  createData('5', 'Jon', 'Snow', 24, 'St. Robert, MO 65584-5678'),
  // createData('6', 'Jon', 'Snow', 24, 'St. Robert, MO 65584-5678'),
  // createData('7', 'Jon', 'Snow', 24, 'St. Robert, MO 65584-5678'),
  // createData('8', 'Jon', 'Snow', 24, 'St. Robert, MO 65584-5678'),
  // createData('9', 'Jon', 'Snow', 24, 'St. Robert, MO 65584-5678'),
  // createData('10', 'Jon', 'Snow', 24, 'St. Robert, MO 65584-5678'),
];

export default function UsersTable() {
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false); // State to track whether "Select All" is checked

  const handleRowSelect = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(rows.map((row) => row.id));
    }
    setSelectAll(!selectAll);
  };

  return (
    <TableContainer component={Paper} sx={{ mt: 3 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" className="table-container">
        <TableHead>
          <TableRow>
            <TableCell
              sx={{ fontWeight: 'bold', fontSize: 14, color: '#f57f52', width: 20 }}
            >
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
              />
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: '#f57f52' }} align='center'>
              ID
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: '#f57f52' }} align="center">
              First Name
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: '#f57f52' }} align="center">
              Last Name
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: '#f57f52' }} align="center">
              Age
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: '#f57f52' }} align="center">
              Address
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: '#f57f52' }} align="center">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
            <TableCell>
              <Checkbox
                checked={selectedRows.includes(row.id)}
                onChange={() => handleRowSelect(row.id)}
              />
            </TableCell>
              <TableCell align='center'>{row.id}</TableCell>
              <TableCell align="center">{row.firstName}</TableCell>
              <TableCell align="center">{row.lastName}</TableCell>
              <TableCell align="center">{row.age}</TableCell>
              <TableCell align="center">{row.address}</TableCell>
              <TableCell align="center"><FiEdit /> <RiDeleteBinLine /></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
