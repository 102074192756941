import React from "react";
import Footer from "../footer";
import Header from "../header";
import NewFooter from "../footer/NewFooter";

const MainLayout = (props) => {
  return (
    <>
      <Header />
      {props.children}
      <NewFooter />
      {/* <Footer /> */}
    </>
  );
};

export default MainLayout;
