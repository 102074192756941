import { useState, useEffect } from "react";
import DebtResultOne from "./debtResultsOne";
import DebtResultTwo from "./debtResultsTwo";
import DebtResultThree from "./debtResultsThree";

function DebtSteps({ debtResult }) {
  
  let pageToSet;
  
  if (debtResult > 85) {
    pageToSet = 0;
  } else if (debtResult >= 70) {
    pageToSet = 1;
  } else {
    pageToSet = 2;
  }

  const componentList = [
    <DebtResultOne debtResult={debtResult} />,
    <DebtResultTwo debtResult={debtResult} />,
    <DebtResultThree debtResult={debtResult} />
  ];

  const [page, setPage] = useState(pageToSet);

  useEffect(() => {
    setPage(pageToSet);
  }, [pageToSet]);

  return (
    <div className="forms">{componentList[page]}</div>
  );
}

export default DebtSteps;
