import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const Email = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Please enter your email address'),
  });

  const handleNext = (values) => {
    setFormData({ ...formData, email: values.email });
    setPage(page + 1);
    setProgressBar(1000);
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">Your <br /> Email </div>
      <div className="form-container">
        <h2 className="form-content-title">What is your email address? *</h2>
        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, touched, errors }) => (
            <Form className="formik-form">
              <Field
                type="text"
                name="email"
                placeholder="Email Address"
                className={`custom-input ${errors.email && touched.email ? 'input-error' : ''}`}
              />
              <ErrorMessage name="email" component="div" className="error-message" />
              <div className="form-buttons">
                <button
                  className="form-button-differ"
                  onClick={() => {
                    setPage(page - 1);
                    setProgressBar(-1000);
                  }}
                >
                  Previous
                </button>
                <button
                  className="form-button"
                  type="submit"
                  disabled={Object.keys(errors).length > 0}
                >
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};

export default Email;
