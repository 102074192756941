import React, { useState, useEffect } from 'react';
import { getQoutes } from '../../common/APIS/GetQoutes/getQoutes';

const FilterButtons = ({ setData, setIsLoading, isLoading }) => {
  const buttons = [
    { id: 1, label: '30 year fixed' },
    { id: 2, label: '25 year fixed' },
    { id: 3, label: '20 year fixed' },
    { id: 4, label: '15 year fixed' },
    { id: 5, label: '10 year fixed' },
    // { id: 6, label: '10/6 ARM' },
    // { id: 7, label: '7/6 ARM' },
    // { id: 8, label: '5/6 ARM' }
  ];

  const [activeButton, setActiveButton] = useState(1);
  const [buttonLabel, setButtonLabel] = useState("30 year fixed");

  const handleButtonClick = (button) => {
    setIsLoading(true);
    setActiveButton(button.id);
    setButtonLabel(button.label);
  };

  useEffect(() => {
      getQoutes(buttonLabel)
        .then((resp) => {
          setData(resp);
          console.log("response", resp);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
  }, [isLoading, buttonLabel, setData]);

  return (
    <div className='filter-bar-container'>
      {buttons.map((button) => (
        <button
          key={button.id}
          onClick={() => handleButtonClick(button)}
          className={activeButton === button.id ? 'active' : 'filter-button'}
          disabled={isLoading}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default FilterButtons;



// Multi selected

// import React, { useState } from 'react';

// const FilterButtons = () => {
//   const buttons = [
//     { id: 1, label: '30-Yr Fixed' },
//     { id: 2, label: '25-Yr Fixed' },
//     { id: 3, label: '20-Yr Fixed' },
//     { id: 4, label: '15-Yr Fixed' },
//     { id: 5, label: '10-Yr Fixed' },
//     { id: 6, label: '10/6 ARM' },
//     { id: 7, label: '7/6 ARM' },
//     { id: 8, label: '5/6 ARM' }
//   ];

//   const [activeButtons, setActiveButtons] = useState([]);

//   const handleButtonClick = (buttonId) => {
//     if (activeButtons.includes(buttonId)) {
//       setActiveButtons(activeButtons.filter((id) => id !== buttonId));
//     } else {
//       setActiveButtons([...activeButtons, buttonId]);
//     }
//   };

//   return (
//     <div className='filter-bar-container'>
//       {buttons.map((button) => (
//         <button
//           key={button.id}
//           onClick={() => handleButtonClick(button.id)}
//           // className='active'
//           className={activeButtons.includes(button.id) ? 'active' : 'filter-button'}
//         >
//           {button.label}
//         </button>
//       ))}
//     </div>
//   );
// };

// export default FilterButtons;



