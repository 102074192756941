import React from 'react'
import './loanOptionsStyles.scss'

const ConventionalLoanTwo = () => {
  return (
    <div className="loanOptionsTypes-container" id="conventional-loan">
        <h3>CONVENTIONAL LOAN</h3>
        <h1 className='title'>Adjustable Rate Mortgage (arm)</h1>
        <h5>If you are planning on staying in a home for 7 years or less, an ARM program might be a great fit for you.</h5>
        <div className="advantages-list">
          <h3 className='advantages-title'>ADVANTAGES:</h3>
          <div className="item">
            <h1>01.</h1>
            <h4>Lower payment during fixed period.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>02.</h1>
            <h4>Potential for rate to decrease over time.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>03.</h1>
            <h4>Great for borrowers looking to pre-pay their principal.</h4>
          </div>
          <div className="divider"></div>
        </div>
        <button className='find-rate-button'>APPLY NOW</button>
    </div>
  )
}

export default ConventionalLoanTwo