import React, { useReducer, useEffect } from "react";
import { allTexts } from "../../common/all-text";
import { BsStarFill } from "react-icons/bs";
import "./reviewStyles.scss";
import { quote } from "../../common/images";
import { ImQuotesRight } from 'react-icons/im';

const ReviewCard = ({ icon, Name, paragraph }) => {
  return (
    <div>
      <section>
        <div className="container">
          <div className="a-box">
            <div className="pt-3 pb-2">
              <div>
                <div className="round-a mx-3">
                  {/* <img
                    src={icon}
                    className="round-a mx-3"
                    height="100px"
                    width="100px"
                  /> */}
                    {icon}
                </div>

                <div className="margin_left-a">
                <ImQuotesRight />
                </div>
              </div>

              <div className="color">
                <div>
                  <p class="card-text-b"> {paragraph}</p>
                </div>

                {/* <h4 className="card-color-a">{Name}</h4> */}
                <div className="review">
                  <BsStarFill className="star" />
                  <BsStarFill className="star" />
                  <BsStarFill className="star" />
                  <BsStarFill className="star" />
                  <BsStarFill className="star" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ReviewCard;
