import "./calculationSteps.scss";
import { useState } from "react";
import RefinanceCalculation from "./calculationStepsForms/refinanceCalculation";
import PurchaseCalculation from "./calculationStepsForms/purchaseCalculation";

function CalculationSteps() {
  const [page, setPage] = useState(0);
  const [active, setActive] = useState(true);

  const componentList = [
    <PurchaseCalculation />,
    <RefinanceCalculation />,
  ];
  return (
    <div className="calculationSteps-container">
      <div className="calculation-steps">
        <div className="calculation-step">
        <button className="calculation-button" 
            onClick={() => {
              setPage(0);
              setActive(true);
            }}>
            PURCHASE
          </button>
        <div className="divider" style={{ display: active ? "block" : "none" }}></div>
        </div>
        <div className="calculation-step">
        <button className="calculation-button" 
            onClick={() => {
              setPage(1);
              setActive(false);
            }}>
              REFINANCE
        </button>
        <div className="divider" style={{ display: active ? "none" : "block" }}></div>
        </div>
      </div>

      <div className="forms">{componentList[page]}</div>
    </div>
  );
}

export default CalculationSteps;