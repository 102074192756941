import { motion } from "framer-motion";

  const NewHome = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        <h1 className="step-title">Hi {formData.firstName}</h1>
        <div className="form-container">
        <h2 className="form-content-title">welcome to a new way home.</h2>
        <p className="form-content" >Inflation Slayer quotes don't require your SSN, won't affect your credit score, and can save you thousands.</p>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 1);
            setProgressBar(-1000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={() => {
            setPage(page + 1);
            setProgressBar(1000);
          }}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default NewHome;