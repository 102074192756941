import { motion } from "framer-motion";
import { useEffect, useState } from "react";

  const GrossSalary = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const salaryTotalSession = sessionStorage.getItem('monthlysalaryAmmount') || 0;
    const initialValue = parseInt(salaryTotalSession, 10); 
    const [monthlySalary, setMonthlySalary] = useState(initialValue);
    const [disable, setDisable] = useState(false);
  
    const handleIncrement = () => {
      setMonthlySalary(parseFloat(monthlySalary) + 1000);
    }
    const handlePurchaseChange = (event) => {
      setMonthlySalary(event.target.value);
      setDisable(true);
    };
    useEffect(() => {
      sessionStorage.setItem('monthlysalaryAmmount', monthlySalary);
      setFormData({ ...formData, monthlySalary: monthlySalary })
    }, [monthlySalary]);

    const handleNext = () => {
      switch (true) {
          case formData.howPaidArray.includes("Commission"):
          setPage(12);
          setProgressBar(12000);
          break;
          case formData.howPaidArray.includes("Bonus"):
          setPage(14);
          setProgressBar(14000);
          break;
        default:
          setPage(18);
          setProgressBar(18000);
          break;
      }
    };
    
  const handlePrevius = () => {
    
    switch (true) {
      case formData.howPaidArray.includes("Overtime") && formData.overtimeIncome=="Yes":
      setPage(page - 1);
      setProgressBar(progressBar - 1000);
      break;
      case formData.howPaidArray.includes("Overtime"):
      setPage(page - 2);
      setProgressBar(progressBar - 2000);
      break;
      case formData.howPaidArray.includes("Hourly"):
      setPage(page - 3);
      setProgressBar(progressBar - 3000);
      break;
    default:
      setPage(6);
      setProgressBar(6000);
      break;
  }
};
    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        {/* <h1 className="step-title">Monthly Salary</h1> */}
        <div className="form-container">
        <h2 className="form-content-title">What is your monthly salary? *</h2>
        <div className="estimated-field">
          <label>Enter Monthly Salary</label>
          <div className="estimated-price">
          <input placeholder={`$ ${monthlySalary}`} onChange={handlePurchaseChange} type="number"/>
        <div className="price-counter">
          <button disabled={disable} className="minus-sign" onClick={() => setMonthlySalary(monthlySalary - 1000)}>-</button>
          <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={handlePrevius}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default GrossSalary;