import { useState, useEffect } from "react";
import CreditResultOne from "./creditResultsOne";
import CreditResultTwo from "./creditResultsTwo";
import CreditResultThree from "./creditResultsThree";

function CreditSteps({ credit }) {
  
  let pageToSet;
  
  if (credit > 85) {
    pageToSet = 0;
  } else if (credit >= 70) {
    pageToSet = 1;
  } else {
    pageToSet = 2;
  }

  const componentList = [
    <CreditResultOne credit={credit} />,
    <CreditResultTwo credit={credit} />,
    <CreditResultThree credit={credit} />
  ];

  const [page, setPage] = useState(pageToSet);

  useEffect(() => {
    setPage(pageToSet);
  }, [pageToSet]);

  return (
    <div className="forms">{componentList[page]}</div>
  );
}

export default CreditSteps;
