import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Toolbar from '@mui/material/Toolbar';
import { Inflationlogo } from '../../../common/images';
import { Menu, MenuItem } from '@mui/material';
import './sidebarStyles.scss'
import { CgProfile, CgMenu } from 'react-icons/cg';
import AdminPanelPages from '../pages';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 240;

function Sidebar(props) {
const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [activePage, setActivePage] = useState(0);

  const handlePageChange = (pageIndex) => {
    setActivePage(pageIndex);
    setPage(pageIndex)
  };

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfile = () => {
    setAnchorEl(null);
    setPage(0)
    setActivePage(0);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    navigate('/adminLogin')
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <div className='sidebar'>
      <img src={Inflationlogo} alt="logo" className='logo'/>
      <Divider className='divider'/>
      <List className='sidebar-list'>
      {/* <button
        className={activePage === 0 ? 'sidebar-link active-button' : 'sidebar-link'}
        onClick={() => handlePageChange(0)}
      >
        Profile
      </button> */}
      <button
        className={activePage === 0 ? 'sidebar-link active-button' : 'sidebar-link'}
        onClick={() => handlePageChange(0)}
      >
        Realtors
      </button>
      <button
        className={activePage === 1 ? 'sidebar-link active-button' : 'sidebar-link'}
        onClick={() => handlePageChange(1)}
      >
        Web Template
      </button>
    </List>
      {/* <Divider className='divider'/> */}
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
        }}
      >
        <Toolbar className='header'>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <CgMenu className='menu-icon'/>
          </IconButton>
            <div className='user-profile'>
            <CgProfile 
            className='profile-icon'
            onClick={handleMenu}
            />
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={handleProfile}>Profile</MenuItem> */}
                <MenuItem onClick={handleLogOut}>Log Out</MenuItem>
              </Menu>
            </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 3, width: { md: `calc(100% - ${drawerWidth}px)` } }}
      >
        <Toolbar />
        <AdminPanelPages page={page} />
      </Box>
    </Box>
  );
}

Sidebar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Sidebar;