import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormInput from "../../reusableComponents/formInput/formInput";
import { SlArrowUp, SlArrowDown } from 'react-icons/sl';
import { getPurchaseCalculation } from "../../../common/APIS/Calculations/purchaseCalculation";

const RefinanceCalculation = () => {
  const validationSchema = Yup.object().shape({
    loanAmount: Yup.number().required("Loan Ammount is required"),
    durationYear: Yup.number().required("Loan term is required"),
    interestRate: Yup.number().required("Interest rate is required"),
  });

  const [advancedOptions, setAdvancedOptions] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [results, setResults] = useState();

  const formik = useFormik({
    initialValues: {
      loanAmount: "350000",
      durationYear: "30",
      interestRate: "4",
    },
    validationSchema,
    onSubmit: (values) => {
      const search = {
        loanAmount: values.loanAmount,
        interestRate: values.interestRate,
        durationYear: values.durationYear,
      };
      getPurchaseCalculation(search)
        .then((resp) => {
          setResults(resp?.data?.monthly_payment);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  });

  useEffect(() => {
    const calculateDownPayment = () => {
      const downPayment = formik.values.loanAmount * 0.1; // Adjust the multiplier as needed
      formik.setFieldValue("downPayment", downPayment);
    };

    calculateDownPayment();
  }, [formik.values.loanAmount, results]);
  return (
    <div className="purchase-calculation-container">
      <div className="afford">
        <h1 className="afford-title">How much money can i save?</h1>
        <p className="afford-heading">Quickly see how much you could save by refinancing with us. No cost, no sweat.</p>
        <div className="calculation-form">
            <FormInput
              label="LOAN AMOUNT"
              name="loanAmount"
              value={formik.values.loanAmount}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.loanAmount && formik.errors.loanAmount}
              placeholder="$350,000.00"
              type="number"
            />

            <FormInput
              label="LOAN TERM"
              name="durationYear"
              value={formik.values.durationYear}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.durationYear && formik.errors.durationYear}
              placeholder="30 years"
              type="number"
            />


            <FormInput
              label="INTEREST RATE"
              name="interestRate"
              value={formik.values.interestRate}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.interestRate && formik.errors.interestRate}
              placeholder="4.00%"
              type="number"
            />

          <div className="form-fields-advance">

            <div className="advanced-options-container">
              <button onClick={() => setAdvancedOptions(!advancedOptions)}>
                ADVANCED
                <SlArrowDown style={{ display: advancedOptions ? "none" : "block" }} />
                <SlArrowUp style={{ display: advancedOptions ? "block" : "none" }} />
              </button>

              {advancedOptions && (
                <div className="advanced-options">
                  <FormInput
                    label="HAZARD INSURANCE"
                    name="hazardInsurance"
                    value={formik.values.hazardInsurance}
                    onChange={formik.handleChange}
                    placeholder="$0"
                  />

                  <FormInput
                    label="MORTGAGE INSURANCE"
                    name="mortgageInsurance"
                    value={formik.values.mortgageInsurance}
                    onChange={formik.handleChange}
                    placeholder="$0"
                  />

                  <FormInput
                    label="PROPERTY TAXES"
                    name="propertyTaxes"
                    value={formik.values.propertyTaxes}
                    onChange={formik.handleChange}
                    placeholder="$0"
                  />
                </div>
              )}
            </div>
          </div>
            <button className="calculation-button" onClick={formik.handleSubmit}>
              Calculator
            </button>
        </div>
      </div>

      {/* {result?.data?.results?.map((row) => ( */}
      <div className="calculation-chart">
        <div className="chart">
        <h2 className="chart-calculated-value">${results?.total || "1671"}</h2>
          <h6 className="chart-heading">EST. MONTHLY <br /> PAYMENT</h6>
        </div>

        <div className="chart-divider"></div>

        <button onClick={() => setShowDetail(!showDetail)}>
          <label>Payment Details</label>
          <label style={{ display: showDetail ? "none" : "block" }}>+</label>
          <label style={{ display: showDetail ? "block" : "none" }}>-</label>
        </button>

        <div className="payment-details" style={{ display: showDetail ? "flex" : "none" }}>
          <div className="chart-divider"></div>

          <div className="payment-detail">
            <div className="payment-title">
              <div className="orange-sign"></div>
              <h6>PRINCIPAL & INTEREST</h6>
            </div>
            <p>${results?.total || "1400"}</p>
          </div>

          <div className="chart-divider"></div>

          <div className="payment-detail">
            <div className="payment-title">
              <div className="blue-sign"></div>
              <h6>HOME INSURANCE</h6>
            </div>
            <p>$ {results?.annual_home_ins || "0"}</p>
          </div>

          <div className="chart-divider"></div>

          <div className="payment-detail">
            <div className="payment-title">
              <div className="red-sign"></div>
              <h6>TAXES</h6>
            </div>
            <p>${results?.property_tax || "0"}</p>
          </div>

          <div className="chart-divider"></div>

          <div className="payment-detail">
            <div className="payment-title">
              <div className="green-sign"></div>
              <h6>MORTGAGE INSURANCE</h6>
            </div>
            <p>${results?.mortgage || "0"}</p>
          </div>
        </div>
      </div>
      {/* ))} */}
    </div>
  );
};

export default RefinanceCalculation;
