import React from 'react'
import './incomeResultsSteps.scss'

const IncomeResultThree = ({ income }) => {
  return (
    <div className='income-result-component'>
        <div className="income-result-headings">
            <h1 className="title">Income Section: Opportunities Ahead – 0-69% Score Range</h1>
            <p className='paragraph'>A 0-69% score in the Income Section signifies a stage of learning and growth. It's an opportunity to better understand your income dynamics and how they connect to the mortgage process.</p>
        </div>
        <div className="percentage">
          <h5>Income Score</h5>
          <h2>{income}%</h2>
          <div className="low-box">
            Low
          </div>
        </div>
    </div>
  )
}

export default IncomeResultThree