import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { IoIosArrowDown } from 'react-icons/io';
import { BsArrowRight } from 'react-icons/bs';
import { Link } from "react-scroll";

export default function LoanActionsAccordions() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className='accordian'>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<IoIosArrowDown />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ fontSize: '20px' }}>
          Conventional Loans
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: 'left' }}>
            Most homebuyers seek conventional loans including fixed-rate and adjustable rate options.
          </Typography>
          <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 500, cursor: 'pointer', mt: 2, color: '#f57f52' }}> 
          <Link
            activeClass="active"
            to="conventional-loan"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            LEARN MORE <BsArrowRight />
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary
          expandIcon={<IoIosArrowDown />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ fontSize: '20px' }}>
          FHA Mortage
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: 'left' }}>
            Backed by the Federal Housing Administration. FHA mortgages offer more lenient underwriting guidelines.
          </Typography>
          <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 500, cursor: 'pointer', mt: 2, color: '#f57f52' }}>
          <Link
            activeClass="active"
            to="fha-mortgage"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            LEARN MORE <BsArrowRight />
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary
          expandIcon={<IoIosArrowDown />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ fontSize: '20px' }}>
          Jumbo Loan
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: 'left' }}>
          Great for larger home purchases and refinances, we offer an array of options for those borrowing over the Fannie Mae / Freddie Mac county loan limits.
          </Typography>
          <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 500, cursor: 'pointer', mt: 2, color: '#f57f52' }}>
          <Link
            activeClass="active"
            to="jumbo-loan"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            LEARN MORE <BsArrowRight />
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary
          expandIcon={<IoIosArrowDown />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ fontSize: '20px' }}>
          Usda Loan
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: 'left' }}>
          Excellent option for borrowers in rural areas or with high-acreage properties.
          </Typography>
          <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 500, cursor: 'pointer', mt: 2, color: '#f57f52' }}>
          <Link
            activeClass="active"
            to="usda-loan"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            LEARN MORE <BsArrowRight />
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
        <AccordionSummary
          expandIcon={<IoIosArrowDown />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ fontSize: '20px' }}>
          VA Loan
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: 'left' }}>
          Available only to active military and veterans, these loans offer up to 103.5% financing, no money down purchases and 100% cash out.
          </Typography>
          <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 500, cursor: 'pointer', mt: 2, color: '#f57f52' }}>
          <Link
            activeClass="active"
            to="va-loan"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            LEARN MORE <BsArrowRight />
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
        <AccordionSummary
          expandIcon={<IoIosArrowDown />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ fontSize: '20px' }}>
          Home Equity Line of Credit
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography sx={{ textAlign: 'left' }}>
          Perfect to pay off debt or remodel your home
          </Typography>
          <Typography sx={{ textAlign: 'left', fontSize: '16px', fontWeight: 500, cursor: 'pointer', mt: 2, color: '#f57f52' }}>
          <Link
            activeClass="active"
            to="home-loan"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
          >
            LEARN MORE <BsArrowRight />
            </Link>
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}