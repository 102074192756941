import './toggleButtons.scss'

function ToggleButtons(props) {
  const { label1, label2, toggleState, onChange } = props;

  return (
    <div>
      <label className="toggle">
        <input type="checkbox" onChange={onChange} checked={toggleState} />
        <div className="slider">
          <span>{label1}</span>
          <span>{label2}</span> 
        </div>
      </label>
    </div>
  );
}

export default ToggleButtons;