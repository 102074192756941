import { motion } from "framer-motion";
import { useEffect, useState } from "react";

  const OtherAmount = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const otherAmounts = sessionStorage.getItem('othersAmount') || 0;
    const initialValue = parseInt(otherAmounts, 10); 
    const [otherAmount, setOtherAmount] = useState(initialValue);
    const [disable, setDisable] = useState(false);
  
    const handleIncrement = () => {
      setOtherAmount(parseFloat(otherAmount) + 1000);
    }
    const handlePurchaseChange = (event) => {
      setOtherAmount(event.target.value);
      setDisable(true);
    };
    useEffect(() => {
      sessionStorage.setItem('othersAmount', otherAmount);
      setFormData({ ...formData, otherAmount: otherAmount })
    }, [otherAmount]);

    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        {/* <h1 className="step-title">Bonus Income</h1> */}
        <div className="form-container">
        <h2 className="form-content-title">How much you paid? *</h2>
        <div className="estimated-field">
          <label>Enter Amount</label>
          <div className="estimated-price">
          <input placeholder={`$ ${otherAmount}`} onChange={handlePurchaseChange} type="number"/>
        <div className="price-counter">
          <button disabled={disable} className="minus-sign" onClick={() => setOtherAmount(otherAmount - 1000)}>-</button>
          <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 1);
            setProgressBar(-1000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={() => {
            setPage(18);
            setProgressBar(18000);
          }}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default OtherAmount;