import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const FullName = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Please Enter Your Full Name'),
  });

  const handleNext = (values) => {
    setFormData({ ...formData, fullName: values.fullName });
    setPage(page + 1);
    setProgressBar(1000);
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">Full <br /> Name </div>
      <div className="form-container">
        <h2 className="form-content-title">Enter Your Full Name *</h2>
        <Formik
          initialValues={{
            fullName: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, touched, errors }) => (
            <Form className="formik-form">
              <Field
                type="text"
                name="fullName"
                placeholder="Full Name"
                className="custom-input"
              />
              <ErrorMessage name="fullName" component="div" className="error-message" />
              <div className="form-buttons">
                <button
                  className="form-button-differ"
                  onClick={() => {
                    setPage(page - 1);
                    setProgressBar(-1000);
                  }}
                >
                  Previous
                </button>
                <button className="form-button" type="submit" disabled={Object.keys(errors).length > 0}>
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};

export default FullName;
