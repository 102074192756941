import { motion } from "framer-motion";
import { useEffect, useState } from "react";

  const GiftAmmount = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const giftAmountSession = sessionStorage.getItem('giftAmounts') || 0;
    const initialValue = parseInt(giftAmountSession, 10); 
    const [giftAmmount, setGiftAmmount] = useState(initialValue);
    const [disable, setDisable] = useState(false);
  
    const handleIncrement = () => {
      setGiftAmmount(parseFloat(giftAmmount) + 1000);
    }
    const handlePurchaseChange = (event) => {
      setGiftAmmount(event.target.value);
      setDisable(true);
    };
    useEffect(() => {
      sessionStorage.setItem('giftAmounts', giftAmmount);
      setFormData({ ...formData, giftAmmount: giftAmmount })
    }, [giftAmmount]);

    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        {/* <h1 className="step-title">Gift Amount</h1> */}
        <div className="form-container">
        <h2 className="form-content-title">What will the amount of your gifts be?</h2>
        <div className="estimated-field">
          <label>Enter Gifts Amount</label>
          <div className="estimated-price">
          <input placeholder={`$ ${giftAmmount}`} onChange={handlePurchaseChange} type="number"/>
        <div className="price-counter">
          <button disabled={disable} className="minus-sign" onClick={() => setGiftAmmount(giftAmmount - 1000)}>-</button>
          <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 1);
            setProgressBar(-1000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={() => {
            setPage(page + 1);
            setProgressBar(1000);
          }}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default GiftAmmount;