import React from 'react'
import MainLayout from '../../components/layout/layout'
import PreQualifyMeResults from '../../components/PreQualifyResults/preQualifyResults'
import Divider from '../../components/reusableComponents/divider'

const PreQualifyResult = () => {
  return (
    <MainLayout>
      <Divider />
      <PreQualifyMeResults />
      <Divider />
    </MainLayout>
  )
}

export default PreQualifyResult