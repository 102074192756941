import { motion } from "framer-motion";
import { useEffect, useState } from "react";

  const LiquidAssets = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const liquidSession = sessionStorage.getItem('liquidAmount') || 0;
    const initialValue = parseInt(liquidSession, 10); 
    const [liquidAssetsAmmount, setLiquidAssetsAmmount] = useState(initialValue);
    const [disable, setDisable] = useState(false);
  
    const handleIncrement = () => {
      setLiquidAssetsAmmount(parseFloat(liquidAssetsAmmount) + 1000);
    }
    const handlePurchaseChange = (event) => {
      setLiquidAssetsAmmount(event.target.value);
      setDisable(true);
    };
    useEffect(() => {
      sessionStorage.setItem('liquidAmount', liquidAssetsAmmount);
      setFormData({ ...formData, liquidAssetsAmmount: liquidAssetsAmmount })
    }, [liquidAssetsAmmount]);

    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        {/* <h1 className="step-title">Liquid Assets</h1> */} 
        <div className="form-container">
        <h2 className="form-content-title">What amount do you have in liquid assets, not including gifts, existing in your own personal checking and savings accounts? *</h2>
        
        <div className="estimated-field">
          <label>Enter Liquid Assets Amount</label>
          <div className="estimated-price">
          <input placeholder={`$ ${liquidAssetsAmmount}`} onChange={handlePurchaseChange} type="number"/>
        <div className="price-counter">
          <button disabled={disable} className="minus-sign" onClick={() => setLiquidAssetsAmmount(liquidAssetsAmmount - 1000)}>-</button>
          <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 1);
            setProgressBar(-1000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={() => {
            setPage(page + 1);
            setProgressBar(1000);
          }}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default LiquidAssets;