import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const HourlyRate = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  
  const selectedHourlyRateFromSession = sessionStorage.getItem('selectedHourlyRate') || 0;
  const initialValue = parseInt(selectedHourlyRateFromSession, 10); 
  const [hourlyRate, setHourlyRate] = useState(initialValue);
  const [disable, setDisable] = useState(false);

  const handleIncrement = () => {
    setHourlyRate(parseFloat(hourlyRate) + 1);
  }
  const handlePurchaseChange = (event) => {
    setHourlyRate(event.target.value);
    setDisable(true)
  };

  useEffect(() => {
    sessionStorage.setItem('selectedHourlyRate', hourlyRate);

    setFormData({ ...formData, hourlyRate: hourlyRate });
  }, [hourlyRate]);



  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="form-container">
        <h2 className="form-content-title">What is your hourly rate? *</h2>
        <div className="estimated-field">
          <label>Enter Hourly Rate</label>
          <div className="estimated-price">
            <input
              placeholder={`$ ${hourlyRate}`} 
              onChange={handlePurchaseChange}
              type="number"
            />
            <div className="price-counter">
              <button disabled={disable} className="minus-sign" onClick={() => setHourlyRate(hourlyRate - 1)}>-</button>
              <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
            </div>
          </div>
        </div>
        <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={() => {
              setPage(page - 1);
              setProgressBar(-1000);
            }}
          >
            Previous
          </button>
          <button
            className="form-button"
            onClick={() => {
              setPage(page + 1);
              setProgressBar(1000);
            }}
          >
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default HourlyRate;
