import React from 'react'
import './totalResultsSteps.scss'
import TotalResults from '../../scoreCharts/allResults'

const TotalResultOne = ({ credit, income, debtResult, assets, creditColor, incomeColor, debtColor, assetsColor }) => {
  return (
    <div className='total-result-component'>
        <div className="total-result-headings">
            <h1 className="title">A Step Closer to Homeownership: 86-100% Score Range</h1>
            <p className='paragraph'>Congratulations on achieving a remarkable score of 86-100% on the PreQual Quiz! This result shows that an approval for a mortgage loan is likely within your reach. You're not just a number in the system; you're a future homeowner, and we're here to support you every step of the way.</p>
        </div>
        <div className="total-results-chart">
            <TotalResults credit={credit} income={income} debtResult={debtResult} assets={assets} creditColor={creditColor} incomeColor={incomeColor} debtColor={debtColor} assetsColor={assetsColor} />
            <div className="hider"></div>
        </div>
    </div>
  )
}

export default TotalResultOne