import React from 'react'
import MainLayout from '../../components/layout/layout'
import ScoreAppSteps from '../../components/purchasingSteps/scoreAppSteps'

const ScoreApp = () => {
  return (
    <MainLayout>
      <ScoreAppSteps />
    </MainLayout>
  )
}

export default ScoreApp