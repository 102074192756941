import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './index.scss'
import AdminHeader from '../adminHeader';
import AdminFooter from '../adminFooter';

const AdminDashboardForm = () => {
  const [formData, setFormData] = useState();

  const [selectedBackgroundImage, setSelectedBackgroundImage] = useState(null);
  const [errorBackgroundImage, setErrorBackgroundImage] = useState(null);

  const handleBackgroundImageChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png'];

    if (file && allowedTypes.includes(file.type)) {
      setSelectedBackgroundImage(URL.createObjectURL(file));
      setErrorBackgroundImage(null);
    } else {
      setSelectedBackgroundImage(null);
      setErrorBackgroundImage('Please Select a Valid Image (JPEG or PNG).');
    }
  };



  const [selectedImage, setSelectedImage] = useState(null);
  const [errorImage, setErrorImage] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png'];

    if (file && allowedTypes.includes(file.type)) {
      setSelectedImage(URL.createObjectURL(file));
      setErrorImage(null);
    } else {
      setSelectedImage(null);
      setErrorImage('Please Select a Valid Image (JPEG or PNG).');
    }
  };

  
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [errorLogo, setErrorLogo] = useState(null);

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png'];

    if (file && allowedTypes.includes(file.type)) {
      setSelectedLogo(URL.createObjectURL(file));
      setErrorLogo(null);
    } else {
      setSelectedLogo(null);
      setErrorLogo('Please Select a Valid Logo (JPEG or PNG).');
    }
  };

  
  const [selectedLenderLogo, setSelectedLenderLogo] = useState(null);
  const [errorLenderLogo, setErrorLenderLogo] = useState(null);

  const handleLenderLogoChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png'];

    if (file && allowedTypes.includes(file.type)) {
      setSelectedLenderLogo(URL.createObjectURL(file));
      setErrorLenderLogo(null);
    } else {
      setSelectedLenderLogo(null);
      setErrorLenderLogo('Please Select a Valid Logo (JPEG or PNG).');
    }
  };

    const initialValues = {
        aboutYourself: '',
        name: '',
        designation: '',
        email: '',
        phone: '',
        address: '',
        aboutCompany: '',
        companyName: '',
        companyAddress: '',
        companyPhone: '',
        companyID: '',
        lenderDescription: '',
        lenderCompanyName: '',
        lenderCompanyURL: '',
        registeredYear: '',
        registeredCompanyName: '',
      };
    
      const validationSchema = Yup.object({
        aboutYourself: Yup.string().required('About Yourself is required'),
        name: Yup.string().required('Name is required'),
        designation: Yup.string().required('Designation is required'),
        email: Yup.string().email('Invalid User Name format').required('User Name is required'),
        phone: Yup.string().required('Phone Number is required'),
        address: Yup.string().required('Address is required'),
        aboutCompany: Yup.string().required('About Company is required'),
        companyName: Yup.string().required('Company Name is required'),
        companyAddress: Yup.string().required('Company Address is required'),
        companyPhone: Yup.string().required('Company Phone Number is required'),
        companyID: Yup.string().required('Company ID is required'),
        lenderDescription: Yup.string().required('Lender Description is required'),
        lenderCompanyName: Yup.string().required('Lender Company Name is required'),
        lenderCompanyURL: Yup.string().required('Lender Company URL is required'),
        registeredYear: Yup.string().required('Company Registered Year is required'),
        registeredCompanyName: Yup.string().required('Registered Company Name is required'),
      });

      const handleSubmit = (values) => {
        // Validate image fields
        if (!selectedBackgroundImage) {
          setErrorBackgroundImage('Please Upload Background Image');
          return;
        }
      
        if (!selectedImage) {
          setErrorImage('Please Upload Loan Officer image');
          return;
        }
      
        if (!selectedLogo) {
          setErrorLogo('Please Upload Company Logo');
          return;
        }
      
        if (!selectedLenderLogo) {
          setErrorLenderLogo('Please Upload Lender Logo');
          return;
        }
      
        // Handle form submission
        console.log('Email:', values);
        setFormData(values);
      };
    
      return (
        <div className="dashboard">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
            <div className="dashboard-container">
                <h1 className='form-title'>Create Your Own Website</h1>
                <Form className='form'>
                <h3>Background Details</h3>
                <div className="user-image">
                  <label>Upload Background Image *</label>
                  <div className='background-image'>
                        <input type="file" onChange={handleBackgroundImageChange} accept="image/*" className='image-input'/>
                        <h6 className='choose-image-text'>Choose Background Image</h6>
                        {selectedBackgroundImage && (
                            <img src={selectedBackgroundImage} alt="Selected" className='uploaded-image'/>
                        )}
                  </div>{errorBackgroundImage && <div style={{ color: 'red' }}>{errorBackgroundImage}</div>}
                </div>
                <h3>Loan Officer Details</h3>
                <div className="user-image">
                  <label>Loan Officer Image *</label>
                  <div className='upload-image'>
                        <input type="file" onChange={handleImageChange} accept="image/*" className='image-input'/>
                        <h6 className='choose-image-text'>Choose Image</h6>
                        {selectedImage && (
                            <img src={selectedImage} alt="Selected" className='uploaded-image'/>
                        )}
                  </div>{errorImage && <div style={{ color: 'red' }}>{errorImage}</div>}
                </div>
                <div className="combined-field">
                    <div className='form-field'>
                      <label htmlFor="email">About YourSelf *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="aboutYourself" 
                          name="aboutYourself"
                          placeholder="Enter Your Self Description" 
                      />
                      <ErrorMessage className='error-message' name="aboutYourself" component="div" />
                    </div>
                    <div className='form-field'>
                      <label htmlFor="email">Name *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="name" 
                          name="name"
                          placeholder="Enter Your Name" 
                      />
                      <ErrorMessage className='error-message' name="name" component="div" />
                    </div>
                  </div>
                  <div className="combined-field">
                    <div className='form-field'>
                      <label htmlFor="email">Designation *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="designation" 
                          name="designation"
                          placeholder="Enter Your Designation" 
                      />
                      <ErrorMessage className='error-message' name="designation" component="div" />
                    </div>
                    <div className='form-field'>
                      <label htmlFor="email">User Name *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="email" 
                          name="email"
                          placeholder="Enter User Name" 
                      />
                      <ErrorMessage className='error-message' name="email" component="div" />
                    </div>
                  </div>
                  <div className="combined-field">
                    <div className='form-field'>
                      <label htmlFor="password">Phone Number *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="phone" 
                          name="phone"
                          placeholder="Enter Phone Number" 
                      />
                      <ErrorMessage className='error-message' name="phone" component="div" />
                    </div>
                    <div className='form-field'>
                      <label htmlFor="address">Address *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="address" 
                          name="address"
                          placeholder="Enter Address" 
                      />
                      <ErrorMessage className='error-message' name="address" component="div" />
                    </div>
                  </div>
                <h3>Company Details</h3>
                <div className="user-image">
                  <label>Company Logo *</label>
                  <div className='upload-image'>
                        <input type="file" onChange={handleLogoChange} accept="image/*" className='image-input'/>
                        <h6 className='choose-image-text'>Choose Logo</h6>
                        {selectedLogo && (
                            <img src={selectedLogo} alt="Selected" className='uploaded-image'/>
                        )}
                  </div>{errorLogo && <div style={{ color: 'red' }}>{errorLogo}</div>}
                </div>
                <div className="combined-field">
                    <div className='form-field'>
                      <label htmlFor="email">About Company *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="aboutCompany" 
                          name="aboutCompany"
                          placeholder="Enter About Company Description" 
                      />
                      <ErrorMessage className='error-message' name="aboutCompany" component="div" />
                    </div>
                    <div className='form-field'>
                      <label htmlFor="email">Company Name *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="companyName" 
                          name="companyName"
                          placeholder="Enter Company Name" 
                      />
                      <ErrorMessage className='error-message' name="companyName" component="div" />
                    </div>
                  </div>
                  <div className="combined-field">
                    <div className='form-field'>
                      <label htmlFor="email">Company Address *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="companyAddress" 
                          name="companyAddress"
                          placeholder="Enter Company Address" 
                      />
                      <ErrorMessage className='error-message' name="companyAddress" component="div" />
                    </div>
                    <div className='form-field'>
                      <label htmlFor="password">Company Phone Number *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="companyPhone" 
                          name="companyPhone"
                          placeholder="Enter Company Phone Number" 
                      />
                      <ErrorMessage className='error-message' name="companyPhone" component="div" />
                    </div>
                  </div>
                  <div className="combined-field">
                    <div className='form-field'>
                      <label htmlFor="address">Company ID *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="companyID" 
                          name="companyID"
                          placeholder="Enter Company ID" 
                      />
                      <ErrorMessage className='error-message' name="companyID" component="div" />
                    </div>
                  </div>
                <h3>Lender Details</h3>
                <div className="user-image">
                  <label>Lender Logo *</label>
                  <div className='upload-image'>
                        <input type="file" onChange={handleLenderLogoChange} accept="image/*" className='image-input'/>
                        <h6 className='choose-image-text'>Choose Logo</h6>
                        {selectedLenderLogo && (
                            <img src={selectedLenderLogo} alt="Selected" className='uploaded-image'/>
                        )}
                  </div>{errorLenderLogo && <div style={{ color: 'red' }}>{errorLenderLogo}</div>}
                </div>
                <div className="combined-field">
                    <div className='form-field'>
                      <label htmlFor="email">Lender Description *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="lenderDescription" 
                          name="lenderDescription"
                          placeholder="Enter Lender Description" 
                      />
                      <ErrorMessage className='error-message' name="lenderDescription" component="div" />
                    </div>
                  </div>
                  <div className="combined-field">
                    <div className='form-field'>
                      <label htmlFor="email">Lender Company Name *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="lenderCompanyName" 
                          name="lenderCompanyName"
                          placeholder="Enter Lender Company Name" 
                      />
                      <ErrorMessage className='error-message' name="lenderCompanyName" component="div" />
                    </div>
                  </div>
                  <div className="combined-field">
                    <div className='form-field'>
                      <label htmlFor="address">Lender Company URL *</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="lenderCompanyURL" 
                          name="lenderCompanyURL"
                          placeholder="Enter Lender Company URL" 
                      />
                      <ErrorMessage className='error-message' name="lenderCompanyURL" component="div" />
                    </div>
                  </div>
                <h3>Website Rights Details</h3>
                <div className="combined-field">
                    <div className='form-field'>
                      <label htmlFor="email">Company Registered Year:</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="registeredYear" 
                          name="registeredYear"
                          placeholder="Enter Registered Year" 
                      />
                      <ErrorMessage className='error-message' name="registeredYear" component="div" />
                    </div>
                  </div>
                  <div className="combined-field">
                    <div className='form-field'>
                      <label htmlFor="email">Registered Company Name:</label>
                      <Field className='form-input-field' 
                          type="text" 
                          id="registeredCompanyName" 
                          name="registeredCompanyName"
                          placeholder="Enter Registered Company Name" 
                      />
                      <ErrorMessage className='error-message' name="registeredCompanyName" component="div" />
                    </div>
                  </div>
                    <button className='login-button' type="submit">Submit</button>
                </Form>
            </div>
            </Formik>
            {formData && (
              <AdminFooter 
                selectedImage={selectedImage} 
                selectedLogo={selectedLogo}
                selectedLenderLogo={selectedLenderLogo}
                selectedBackgroundImage={selectedBackgroundImage}
                formData={formData}
              />
            )}
        </div>
      );
    };
export default AdminDashboardForm