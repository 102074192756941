import React from 'react'
import './assetsResultsSteps.scss'

const AssetsResultOne = ({ assets }) => {
  return (
    <div className='assets-result-component'>
        <div className="assets-result-headings">
            <h1 className="title">Assets Section: Flourishing Foundations – 86-100% Score Range</h1>
            <p className='paragraph'>An 86-100% score in the Assets Section denotes a flourishing foundation. Having a solid understanding and management of your assets demonstrates preparedness in the mortgage process.</p>
        </div>
        <div className="percentage">
          <h5>Assets Score</h5>
          <h2>{assets}%</h2>
          <div className="high-box">
            High
          </div>
        </div>
    </div>
  )
}

export default AssetsResultOne