import { motion } from "framer-motion";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from "react";

const CreditScore = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required('Please select an option'),
  });

  const handleNext = (values) => {
    console.log("credit Value", values.selectedOption)
    setFormData({ ...formData, creditScore: values.selectedOption });

    // Store the selected option in sessionStorage
    sessionStorage.setItem('selectedCreditOption', values.selectedOption);

    if (values.selectedOption === "Poor (Below 580)") {
      setPage(page + 1);
      setProgressBar(progressBar + 1000);
    } else {
      setPage(page + 6);
      setProgressBar(progressBar + 6000);
    }
  };

  // Retrieve the selected option from sessionStorage
  const selectedOptionFromSession = sessionStorage.getItem('selectedCreditOption') || '';

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="form-container">
        <h2 className="form-content-title">What is your current credit score? *</h2>
        <Formik
          initialValues={{
            selectedOption: selectedOptionFromSession, // Set the initial value from session
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, touched }) => (
            <Form>
              <div className="radio-options">
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="Excellent (700+)" />
                  Excellent (700+)
                </label>
                <label className="radio-option"> 
                  <Field type="radio" name="selectedOption" value="Good (660-699)" />
                  Good (660-699)
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="Fair (620-659)" />
                  Fair (620-659)
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="Below Average (580-619)" />
                  Below Average (580-619)
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="Poor (Below 580)" />
                  Poor (Below 580)
                </label>
              {touched.selectedOption && !values.selectedOption && (
                <div className="error-message">Please select an option</div>
              )}
              </div>
              <div className="form-buttons">
                <button className="form-button" type="submit" disabled={!values.selectedOption && touched.selectedOption}>
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};

export default CreditScore;
