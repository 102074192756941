import React from 'react'
import './loanOptionStyles.scss'
import LoanActionsAccordions from './accordian'

const LoanOption = () => {
  return (
    <div className="loanOption-container">
        <h1>LOAN OPTIONS</h1>
        <h5>Choose from a loan option below to learn more or let the experts at Loan Pronto take care of all your lending questions.</h5>
        <button className='find-rate-button'>CHAT WITH A LOAN SPECIALIST</button>
        <LoanActionsAccordions />
    </div>
  )
}

export default LoanOption