/* eslint-disable react/prop-types */
import React from "react";
import "./style.scss";
import CountUp from "react-countup";

function PurchaseCard(props) {
  return (
      <div className="a-box-small">
        <div className="pt-3 pb-2">
          <img
            src={props.icon}
            className="round-box"
            height="90px"
            width="90px"
          />
          <div className="card-body">
            <div className="color-count">
              <CountUp
                delay={2}
                end={props.num}
                prefix={props.pre}
                separator=" "
                suffix={props.suf}
                decimals={props.dec}
                decimal={props.decValue}
              />
            </div>
            <div className="color-box">
              <p>{props.title}</p>
            </div>

            <p className="card-c"> {props.paragraph}</p>
          </div>
        </div>
      </div>
  );
}

export default PurchaseCard;
