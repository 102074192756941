import { useState, useEffect } from "react";
import AssetsResultOne from "./assetsResultsOne";
import AssetsResultTwo from "./assetsResultsTwo";
import AssetsResultThree from "./assetsResultsThree";

function AssetsSteps({ assets }) {
  
  let pageToSet;
  
  if (assets > 85) {
    pageToSet = 0;
  } else if (assets >= 70) {
    pageToSet = 1;
  } else {
    pageToSet = 2;
  }

  const componentList = [
    <AssetsResultOne assets={assets} />,
    <AssetsResultTwo assets={assets} />,
    <AssetsResultThree assets={assets} />
  ];

  const [page, setPage] = useState(pageToSet);

  useEffect(() => {
    setPage(pageToSet);
  }, [pageToSet]);

  return (
    <div className="forms">{componentList[page]}</div>
  );
}

export default AssetsSteps;
