import React from 'react'
import RefinanceProcessResults from '../../components/refinanceProcessResult/refinanceProcessResults'
import MainLayout from '../../components/layout/layout'

const RefinanceProcessResult = () => {
  return (
    <MainLayout>
      <RefinanceProcessResults />
    </MainLayout>
  )
}

export default RefinanceProcessResult