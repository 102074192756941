import AdminProfile from "./profile";
import AdminUsers from "./users";
import AdminTemplate from "./template";

function CalculationSteps({page}) {

  const componentList = [
    // <AdminProfile />,
    <AdminUsers />,
    <AdminTemplate />
  ];
  return (
      <div className="forms">{componentList[page]}</div>
  );
}

export default CalculationSteps;