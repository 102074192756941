import React from 'react'
import './creditResultsSteps.scss'

const CreditResultOne = ({ credit }) => {
  return (
    <div className='credit-result-component'>
        <div className="credit-result-headings">
            <h1 className="title">Credit Section: Strong Footing – 86-100% Score Range</h1>
            <p className='paragraph'>Your impressive 86-100% score in the Credit Section signifies a robust credit standing. Managing your credit responsibly is a vital aspect of the mortgage application process, and this high score reflects your attentiveness and discipline.</p>
        </div>
        <div className="percentage">
          <h5>Credit Score</h5>
          <h2>{credit}%</h2>
          <div className="high-box">
            High
          </div>
        </div>
    </div>
  )
}

export default CreditResultOne