import React from 'react'
import './prequalifyResults.scss'
import { useLocation } from "react-router-dom";
import TotalResultsSteps from './scoreResults/TotalResults/totalResultsSteps'
import CreditSteps from './scoreResults/CreditResults/creditSteps'
import IncomeSteps from './scoreResults/IncomeResults/incomeSteps'
import DebtSteps from './scoreResults/DebtResults/debtSteps';
import AssetsSteps from './scoreResults/assetsResults/assetsSteps';

const PreQualifyMeResults = () => {
  const location = useLocation();
  const results = location.state;

  let PITI;
  let totalIncome;
  let monthlSalary=results.bonusIncomeAmount;

  const hourlyIncome = (results.weeklyHours * results.hourlyRate) * 4.33;
  const bonusIcome = results.bonusIncomeAmount/12;
  const overTimeIncome = results.overtimeIncomeAmount/12;
  const commissionIncome = results.commissionIncomeAmount/12;

  totalIncome = hourlyIncome +  + bonusIcome + overTimeIncome + commissionIncome;
  
    // PITI=(estimatedPrice*0.007);
    // const DEBT = parseInt(results.debtAmount, 10);
    // const First = PITI + DEBT;
    // const Second = First / totalIncome;
    // const DTI = Second * 100;
    totalIncome = parseFloat(totalIncome);
  console.log("totalIncome", totalIncome)

  let income;
  
  switch (true) {
    case totalIncome > 50000:
      income = 100;
      break;
    case totalIncome > 30000:
      income = 80;
      break;
    case totalIncome > 10000:
      income = 60;
      break;
    case totalIncome > 100:
      income = 40;
      break;
    default:
      income = 0;
  }
  
  income = parseFloat(income); // Convert income to a floating-point number (decimal).
  
  console.log("income", income);

  let credit;

  switch (results.creditScore) {
    case "Excellent (700+)":
      credit = 100;
      break;
    case "Good (660-699)":
      credit = 80;
      break;
    case "Fair (620-659)":
      credit = 60;
      break;
    case "Below Average (580-619)":
      credit = 40;
      break;
    case "Poor (Below 580)":
      credit = 0;
      break;
    default:
      credit = 0;
  }
  
  credit = parseInt(credit); // Convert credit to an integer.
  
  console.log("credit", credit);
  


  const DEBT = parseInt(results.debtAmount, 10);
  const debt = DEBT / totalIncome;
  
  console.log("debt", debt);
  
  let debtResult;
  
  switch (true) {
    case debt > 50:
      debtResult = 0;
      break;
    case debt > 43:
      debtResult = 40;
      break;
    case debt > 36:
      debtResult = 60;
      break;
    case debt > 26:
      debtResult = 80;
      break;
    default:
      debtResult = 100;
  }
  
  debtResult = parseInt(debtResult); // Convert debtResult to an integer.
  
  console.log("debtResult", debtResult);
  


   
  let assets;

switch (true) {
  case results.liquidAssetsAmmount > 50000:
    assets = 100;
    break;
  case results.liquidAssetsAmmount > 30000:
    assets = 80;
    break;
  case results.liquidAssetsAmmount > 10000:
    assets = 60;
    break;
  case results.liquidAssetsAmmount > 100:
    assets = 40;
    break;
  default:
    assets = 0;
}

assets = parseInt(assets); // Convert assets to an integer.

console.log("assets", assets);


  return (
    <div className='pre-qualify-results-container'> 
            <TotalResultsSteps credit={credit} income={income} debtResult={debtResult} assets={assets}/>
        <div className="circles">
          <div className="score-gained">
            <div className="low-circle"></div>
            <p className='circle-heading'>Low</p>
          </div>
          <div className="score-gained">
            <div className="medium-circle"></div>
            <p className='circle-heading'>Medium</p>
          </div>
          <div className="score-gained">
            <div className="high-circle"></div>
            <p className='circle-heading'>High</p>
          </div>
        </div> 
        <div className="score-cards">
            <CreditSteps credit={credit} />
            <IncomeSteps income={income} />
            <DebtSteps debtResult={debtResult} />
            <AssetsSteps assets={assets} />
        </div>
    </div>
  )
}

export default PreQualifyMeResults