import { motion } from "framer-motion";
import { useEffect, useState } from "react";

  const RuralHomePurchasePrice = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const ruralSession = sessionStorage.getItem('ruralAmount') || 0;
    const initialValue = parseInt(ruralSession, 10); 

    const [ruralPrice, setRuralPrice] = useState(initialValue);
    const [disable, setDisable] = useState(false);
  
    const handleIncrement = () => {
      setRuralPrice(parseFloat(ruralPrice) + 1000);
    }
    const handlePurchaseChange = (event) => {
      setRuralPrice(event.target.value);
      setDisable(true);
    };
    useEffect(() => {
      sessionStorage.setItem('ruralAmount', ruralPrice);
      setFormData({ ...formData, ruralHomePurchasePrice: ruralPrice })
    }, [ruralPrice]);
    
    let PITI;
    let totalIncome;
    let monthlSalary=formData.bonusIncomeAmount;

    const hourlyIncome = (formData.weeklyHours * formData.hourlyRate) * 4.33;
    const bonusIcome = formData.bonusIncomeAmount/12;
    const overTimeIncome = formData.overtimeIncomeAmount/12;
    const commissionIncome = formData.commissionIncomeAmount/12;

    totalIncome = hourlyIncome +  + bonusIcome + overTimeIncome + commissionIncome;
    
      PITI=(ruralPrice*0.007);
      const DEBT = parseInt(formData.debtAmount, 10);
      const First = PITI + DEBT;
      const Second = First / totalIncome;
      const DTI = Second * 100;
  
  const onSubmit = () => {
    console.log("dti", DTI)
    if(DTI <= 43){
      setPage(page + 1);
      setProgressBar(1000); 
    }
    else{
      setPage(page + 2);
      setProgressBar(2000); 
    }
  };
console.log("piti", PITI)

    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        {/* <h1 className="step-title">Rural Home ?</h1> */}
        <div className="form-container">
        <h2 className="form-content-title">What is your desired purchase price?</h2>
        <div className="estimated-field">
          <label>PURCHASE PRICE</label>
          <div className="estimated-price">
          <input placeholder={`$ ${ruralPrice}`} onChange={handlePurchaseChange} type="number"/>
        <div className="price-counter">
          <button disabled={disable} className="minus-sign" onClick={() => setRuralPrice(ruralPrice - 1000)}>-</button>
          <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 2);
            setProgressBar(-2000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={onSubmit}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default RuralHomePurchasePrice;