import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const HowPaidOther = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const otherMethod = sessionStorage.getItem('otherPayMethod') || "";
  const [otherPaidMethod, setOtherPaidMethod] = useState(otherMethod);
  const [error, setError] = useState(false); // New error state

  const handlePurchaseChange = (event) => {
    setOtherPaidMethod(event.target.value);
    setError(false); // Clear error when input changes 
  };

  useEffect(() => {
    sessionStorage.setItem('otherPayMethod', otherPaidMethod);
    setFormData({ ...formData, otherPaidMethod: otherPaidMethod });
  }, [otherPaidMethod]);

  const handleNextClick = () => {
    if (otherPaidMethod.trim() === "") {
      setError(true);
    } else {
      setError(false);
      setPage(page + 1);
      setProgressBar(1000);
    }
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="form-container">
        <h2 className="form-content-title">How are you paid? *</h2>
        <div className="estimated-field">
          <label>Enter How Are You Paid</label>
          <div className="estimated-price">
            <input
              placeholder="Please Enter How Are You Paid"
              value={otherPaidMethod}
              onChange={handlePurchaseChange}
              className={error ? "error" : ""}
            />
          </div>
            {error && <p className="error-message">Please enter how are you paid</p>}
        </div>
        <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={() => {
              setPage(6);
              setProgressBar(6000);
            }}
          >
            Previous
          </button>
          <button className="form-button" onClick={handleNextClick}>
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default HowPaidOther;
