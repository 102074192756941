import { motion } from "framer-motion";

const VeteranPass = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      {/* <div className="step-title">Requirement?</div> */}
      <div className="form-container">
        <h2 className="form-content-title">CONGRATULATIONS!</h2>
              <p className="text">The information you provided appears to support your goal of borrowing $ {formData.conventionalPurchasePrice} towards the purchase of your home.</p>
              <div className="form-buttons">
                <button
                  className="form-button-differ"
                  onClick={() => {
                    setPage(page - 1);
                    setProgressBar(-1000); 
                  }}
                >
                  Previous
                </button>
                <button className="form-button"
                  onClick={() => {
                    setPage(page + 2);
                    setProgressBar(2000); 
                  }}>
                  Next
                </button>
              </div>
      </div>
    </motion.div>
  );
};

export default VeteranPass;
