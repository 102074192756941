import { motion } from "framer-motion";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const PriceRange = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required('Please Select Price Range'),
  });

  const handleNext = (values) => {
    setFormData({ ...formData, priceRange: values.selectedOption });
    setPage(page + 1);
    setProgressBar(1000);
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">Price <br /> Range</div>
      <div className="form-container">
        <h2 className="form-content-title">What is your price range? *</h2>
        <Formik
          initialValues={{
            selectedOption: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, touched }) => (
            <Form>
              <div className="radio-options">
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="under $100,000" />
                  under $100,000
                </label>
                <label className="radio-option"> 
                  <Field type="radio" name="selectedOption" value="$100,000 - $150,000" />
                  $100,000 - $150,000
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="$150,000 - $200,000" />
                  $150,000 - $200,000
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="$200,000 - $250,000" />
                  $200,000 - $250,000
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="$250,000 - $300,000" />
                  $250,000 - $300,000
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="$300,000 - $350,000" />
                  $300,000 - $350,000
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="$350,000 - $400,000" />
                  $350,000 - $400,000
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="$400,000 or more" />
                  $400,000 or more
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="I don’t know" />
                  I don’t know
                </label>
              {touched.selectedOption && !values.selectedOption && (
                <div className="error-message">Please select an option</div>
              )}
              </div>
              <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={() => {
              setPage(page - 1);
              setProgressBar(-1000);
            }}
          >
            Previous
          </button>
                <button className="form-button" type="submit" disabled={!values.selectedOption && touched.selectedOption}>
                  Next
                </button>
        </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};

export default PriceRange;
