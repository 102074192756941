import React from 'react';
import './formSelectStyles.scss'

function FormSelect({ label, value, options, onChange }) {
  return (
    <div className='custom-form-select'>
      <label>{label}</label>
      <select value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default FormSelect