import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const PhoneNumber = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const validationSchema = Yup.object().shape({
    phoneNumber: Yup.string().required('Please Enter Your Phone Number'),
  });

  const handleNext = (values) => {
    setFormData({ ...formData, phoneNumber: values.phoneNumber });
    setPage(page + 1);
    setProgressBar(1000);
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">Your <br /> Phone <br /> Number </div>
      <div className="form-container">
        <h2 className="form-content-title">What is your Your Phone Number? *</h2>
        <Formik
          initialValues={{
            phoneNumber: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, touched, errors }) => (
            <Form className="formik-form">
              <Field
                type="number"
                name="phoneNumber"
                placeholder="Your Phone Number"
                className="custom-input"
              />
              <ErrorMessage name="phoneNumber" component="div" className="error-message" />
              <div className="form-buttons">
              <button
                className="form-button-differ"
                onClick={() => {
                  setPage(page - 1);
                  setProgressBar(-1000);
                }}
              >
                Previous
              </button>
                <button className="form-button" type="submit" disabled={Object.keys(errors).length > 0}>
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};

export default PhoneNumber;
