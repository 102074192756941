import React from 'react'
import './loanOptionsStyles.scss'

const HomeLoan = () => {
  return (
    <div className="loanOptionsTypes-container" id="home-loan">
        <h3>HOME LOAN</h3>
        <h1 className='title'>Home Equity Line of Credit</h1>
        <h5>Perfect to pay off debt or remodel your home.</h5>
        <div className="advantages-list">
          <h3 className='advantages-title'>ADVANTAGES:</h3>
          <div className="item">
            <h1>01.</h1>
            <h4>Lower payments – interest only for 10-15 years with an additional 10-15 to pay the loan off.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>02.</h1>
            <h4>Flexible spending and payment options.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>03.</h1>
            <h4>Borrow up to 89.99% of your home’s value.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>04.</h1>
            <h4>Can use as much or as little of the credit line as needed.</h4>
          </div>
          <div className="divider"></div>
        </div>
        <button className='find-rate-button'>APPLY NOW</button>
    </div>
  )
}

export default HomeLoan