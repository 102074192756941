import { motion } from "framer-motion";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const CurrentlyMonthlyPayment = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required('Please select an option'),
  });

  const handleNext = (values) => {
    setFormData({ ...formData, currentlyMonthlyPayment: values.selectedOption });
    setPage(page + 1);
    setProgressBar(1000);
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">Current <br /> Monthly <br /> Payment</div>
      <div className="form-container">
        <h2 className="form-content-title">What Is Your Current Monthly Payment ? *</h2>
        <Formik
          initialValues={{
            selectedOption: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, touched }) => (
            <Form>
              <div className="radio-options">
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="$0-$999" />
                  $0-$999
                </label>
                <label className="radio-option"> 
                  <Field type="radio" name="selectedOption" value="$1,000-1,499" />
                  $1,000-1,499
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="$1,500-$1,999" />
                  $1,500-$1,999
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="$2,000-$2,499" />
                  $2,000-$2,499
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="$2,500-$2,999" />
                  $2,500-$2,999
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="$3,000+" />
                  $3,000+
                </label>
              {touched.selectedOption && !values.selectedOption && (
                <div className="error-message">Please select an option</div>
              )}
              </div>
              <div className="form-buttons">
                <button
                  className="form-button-differ"
                  onClick={() => {
                    setPage(page - 1);
                    setProgressBar(-1000);
                  }}
                >
                  Previous
                </button>
                <button className="form-button" type="submit" disabled={!values.selectedOption && touched.selectedOption}>
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};

export default CurrentlyMonthlyPayment;
