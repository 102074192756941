import React from "react";
import "./style.scss";
import { allTexts } from "../../common/all-text";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Inflationlogo } from "../../common/images";
import Typography from "@mui/material/Typography";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = () => {
  return (
      <div className="main-header">
        <Navbar className="main-navbar" collapseOnSelect expand="lg">
          <Navbar.Brand className="logo" href="/">
            <img src={Inflationlogo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="navbar-links">
              <Nav.Link href="/purchasingProcess" className="fa-1x">
                {allTexts.navLinks.buyHome}
              </Nav.Link>
              <Nav.Link href="/refinanceProcess" className="fa-1x">
                {allTexts.navLinks.refinance}
              </Nav.Link>
              <Nav.Link href="/heloc" className="fa-1x">
                {allTexts.navLinks.heLoc}
              </Nav.Link>
              <Nav.Link href="/loanOptions" className="fa-1x">
                {allTexts.navLinks.loanOptions}
              </Nav.Link>
              <Nav.Link href="/calculator" className="fa-1x">
                {allTexts.navLinks.calculators}
              </Nav.Link>
              <Nav.Link href="/" className="fa-1x">
                {allTexts.navLinks.about}
              </Nav.Link>
              {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">
                  Another action
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">
                  Something
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item>
              </NavDropdown> */}
              <div className="header-buttons">
            <Nav.Link href="/" >
              <button className="primary-Btn">
                Live Rates
              </button>
              </Nav.Link>
            <Nav.Link href="/preQualify" >
              <button className="prequalify-Btn">
                {allTexts.buttonTexts.preQualifyMeNow}
              </button>
              </Nav.Link>
            <Nav.Link href="/applyNow" >
              <button className="primary-Btn">
                {allTexts.buttonTexts.applyNow}
              </button>
              </Nav.Link>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
  );
};

export default Header;
