import React from 'react'
import './creditResultsSteps.scss'

const CreditResultTwo = ({ credit }) => {
  return (
    <div className='credit-result-component'>
        <div className="credit-result-headings">
            <h1 className="title">Credit Section: Progressing Well – 70-85% Score Range</h1>
            <p className='paragraph'>Your 70-85% score in the Credit Section shows that you're on the right track. While there may be some areas for improvement, your credit history appears generally healthy.</p>
        </div>
        <div className="percentage">
          <h5>Credit Score</h5>
          <h2>{credit}%</h2>
          <div className="medium-box">
            Medium
          </div>
        </div>
    </div>
  )
}

export default CreditResultTwo