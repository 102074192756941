import React from "react";
import "./modal.scss";

const Modal = ({ title, content, onClose }) => {
  return (
    <div className="modal" onClick={onClose}>
      <div className="modal-content">
          <h2 className="title">{title}</h2>
        <p>{content}</p>
      </div>
    </div>
  );
};

export default Modal;
