import React from 'react'
import './loanOptionsStyles.scss'

const JumboLoan = () => {
  return (
    <div className="loanOptionsTypes-container" id="jumbo-loan">
        <h3>JUMBO LOAN</h3>
        <h1 className='title'>Jumbo Loan</h1>
        <h5>Great for larger home purchases and refinances, we offer an array of options for those borrowing over the Fannie Mae / Freddie Mac county loan limits.</h5>
        <div className="advantages-list">
          <h3 className='advantages-title'>ADVANTAGES:</h3>
          <div className="item">
            <h1>01.</h1>
            <h4>Allows borrower to borrower more than the Fannie Mae/Freddie Mac loan limits.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>02.</h1>
            <h4>Best for second homes & vacation homes.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>03.</h1>
            <h4>Allows for 15% down payment.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>04.</h1>
            <h4>Great for refinance or purchase.</h4>
          </div>
          <div className="divider"></div>
        </div>
        <button className='find-rate-button'>APPLY NOW</button>
    </div>
  )
}

export default JumboLoan