import React from "react";
import { MainLayout } from "../../components";
import LoanOption from "../../components/loanOptions/loanOption/loanOption";
import ConventionalLoan from "../../components/loanOptions/loanOptionsTypes/conventionalLoan";
import ConventionalLoanTwo from "../../components/loanOptions/loanOptionsTypes/conventionalLoanTwo";
import FHAMortage from "../../components/loanOptions/loanOptionsTypes/fhaMortage";
import JumboLoan from "../../components/loanOptions/loanOptionsTypes/jumboLoan";
import UsdaLoan from "../../components/loanOptions/loanOptionsTypes/usdaLoan";
import VALoan from "../../components/loanOptions/loanOptionsTypes/vaLoan";
import HomeLoan from "../../components/loanOptions/loanOptionsTypes/homeLoan";

const LoanOptions = () => {
  return (
    <MainLayout>
      <LoanOption />
      <ConventionalLoan />
      <ConventionalLoanTwo />
      <FHAMortage />
      <JumboLoan />
      <UsdaLoan />
      <VALoan />
      <HomeLoan />
    </MainLayout>
  );
};

export default LoanOptions;
