export const allTexts = {
  buttonTexts: {
    applyNow: "Apply Now",
    messageButton: "Send Message",
    preQualifyMeNow: "Prequalify Me Now"
  },
  headings: {
    freeRates: "great rates + free quotes",
    headline:
      "Get your quote now to lock in a low interest rate with no junk fees.",
    purchase: "Purchase",
    accomplishments: "Our Accomplishments",
    refinance: "Refinance",
    happyCustomer: "Delighted Borrowers",
    contactSection: "Contact Us!",
    loanOptions: "LOAN OPTIONS",
    LoanPreparation: "LOAN PREPARATION",
    SpecialPrograms: "SPECIAL PROGRAMS",
    AboutUs: "ABOUT US",
    right: "Copyright © 2023 Inflation Slayer.  All rights reserved.",

    workWithUs: "WORK WITH US",
    OurTeam: "OUR TEAM",
    Calculators: "CALCULATORS",
    Faqs: "FAQS",
    Disclaimer: "DISCLAIMERS",
    Blog: "BLOG",
    Contact: "CONTACT",
    Licensing: "LICENSING",
  },
  navLinks: {
    buyHome: "Buy a Home",
    refinance: "Refinance",
    calculators: "Calculators",
    preQualify: "Pre Qualify",
    free: "FREE RATE QUOTE",
    about: "About Us",
    heLoc: "HELOC",
    loanOptions: "Loan Options",
  },
  paragraphs: {
    footerSub: "Technology you can  ",
    loans: "WE MAKE LOANS SIMPLE BECAUSE THEY SHOULD BE",
    refinanceCard: "a new, better loan that pays off your old mortgage loan",

    reviewOne: "Inflation Slayer made my life so much easier! Checking rates in real time and finding suitable payments was a breeze. Their user-friendly interface and efficient tools saved me time and hassle. Highly recommended!''- Alex D",
    reviewTwo: "I can't thank Inflation Slayer much enough for their prequal form. It provided me with valuable insights and the results gave me the confidenceto take the next step and talk to a loan officer. It's empowering to know where I stand in the mortgage process. Fantastic service!'' - Sarrah K.",
    reviewThree: "Completing the mortgage application online with Inflation Slayer was an absolute delight. The process was smooth, and the immediate email notification saying 'Received and will be in touch' was a breath of fresh air. Their responsiveness and attention to detail truly stood out. Thank you!'' - Mark R.",
    reviewFour: "Just finished up a new mortgage with Inflation Slayer team and could not be more pleased. They have been there every step and provided great feedback. I would highly recommend!!",
    digitalCard:
      "Engage Your Target Customers at the Right Time on the Right Platform through digital marketing.",
    purchaseCard:
      "Simplifying the process and helping purchasers lock in a low interest rate with no junk fees.",
    application: "FOR OUR INITIAL APPLICATION",
    satisfaction: "EXPERIMENT WITH YOUR POSSIBILITIES",
    contactMain:
      "Let Us Know What You’re Looking for, we’ll always thrilled to help you.",
    year: "INSTANT ONLINE RESULTS",
  },
  links: {
    refinanceCard: "refinanceProcess",
    purchaseCard:"purchasingProcess",
  },
  placeHolders: {
    phone: "+92 322 123 4567",
    emailAddress: "",
    name: "Name",
    subject: "Subject",
    email: "Email",
    message: "Message",
    customerOne: "John Venezia",
    customerTwo: "Dakota Burrell",
    customerThree: "Kim Zwinge",
    customerFour: "Barry Hebda",
    profileOne: "AD",
    profileTwo: "SK",
    profileThree: "MR",
    profileFour: "BH",
    time: " average time to apply",
    avgReview: "Mortgage Calculator",
    avgLoan: "Average Time to Prequalify",
  },
};
