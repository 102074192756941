import React from 'react'
import MainLayout from '../../components/layout/layout'
import RefinanceSteps from '../../components/purchasingSteps/refinanceSteps'

const RefinanceProcess = () => {
  return (
    <MainLayout>
      <RefinanceSteps />
    </MainLayout>
  )
}

export default RefinanceProcess