import React from "react";
import "./footerStyle.scss";
import { allTexts } from "../../common/all-text";
import { insta, linkedIn, fb, InflationlogoDark } from "../../common/images";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    subtitle1: {
      fontSize: 14,
      fontWeight: 500,
    },
  },
  TextField: {
    standard: {
      borderRadius: 3,
      border: 0,
    },
  },
});

const Footer = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <div className="footerSection">
          <Grid
            container
            component="main"
            sx={{ pt: 1, mb: 10, mx: 10, pl: 10 }}
          >
            <CssBaseline />
            <Box sx={{ mt: 3 }}>
              <Grid item xs={12} sm={4} md={3}>
                <Grid item md>
                  <Box sx={{ mt: 5, mb: 2 }}>
                    {" "}
                    <img src={InflationlogoDark} width="220" height="100" />
                  </Box>
                  <Box
                    className="footerSpace"
                    sx={{
                      mt: 5,
                      mb: 2,
                      display: "flex",
                      wordSpacing: 1,
                    }}
                  >
                    <Box sx={{ pt: 2, mx: 1 }}>
                      {" "}
                      <img
                        className="footerIcon"
                        src={fb}
                        width="43px"
                        height="43px"
                      />
                    </Box>{" "}
                    <Box sx={{ pt: 2, mx: 1 }}>
                      {" "}
                      <img
                        className="footerIcon"
                        src={insta}
                        width="43px"
                        height="43px"
                      />
                    </Box>{" "}
                    <Box sx={{ pt: 2, mx: 1 }}>
                      {" "}
                      <img
                        className="footerIcon"
                        src={linkedIn}
                        width="43px"
                        height="43px"
                      />
                    </Box>{" "}
                  </Box>
                </Grid>
              </Grid>

              <Box />

              <Box />
            </Box>
            <Grid item xs={12} sm={4} md={3}>
              <Box sx={{ pt: 10, pl: 5, mx: 8 }}>
                <Typography
                  variant="subtitle1"
                  align="left"
                  color="text.secondary"
                  component="p"
                >
                  <Typography
                    component="h1"
                    variant="h5"
                    align="left"
                    fontWeight="500"
                    color="white"
                    fontSize="16px"
                    letterSpacing="4px"
                  >
                    {allTexts.headings.loanOptions}

                    <Box sx={{ pt: 3 }}>{allTexts.headings.AboutUs}</Box>
                    <Box sx={{ pt: 3 }}>{allTexts.headings.OurTeam}</Box>
                    <Box sx={{ pt: 3 }}>{allTexts.headings.Calculators}</Box>
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={false} sm={4} md={3}>
              <Box sx={{ pt: 10 }}>
                <Typography
                  component="h1"
                  variant="h5"
                  align="left"
                  fontWeight="500"
                  color="white"
                  fontSize="16px"
                  letterSpacing="4px"
                >
                  {allTexts.headings.SpecialPrograms}

                  <Box sx={{ pt: 3 }}>{allTexts.headings.LoanPreparation}</Box>
                  <Box sx={{ pt: 3 }}>{allTexts.headings.Faqs}</Box>
                  <Box sx={{ pt: 3 }}>{allTexts.headings.Contact}</Box>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={false} sm={4} md={3}>
              <Box sx={{ pt: 10 }}>
                <Typography
                  component="h1"
                  variant="h5"
                  align="left"
                  fontWeight="500"
                  color="white"
                  fontSize="16px"
                  letterSpacing="4px"
                >
                  {allTexts.headings.Blog}

                  <Box sx={{ pt: 3 }}>{allTexts.headings.Disclaimer}</Box>
                  <Box sx={{ pt: 3 }}>{allTexts.headings.workWithUs}</Box>
                  <Box sx={{ pt: 3 }}>{allTexts.headings.Licensing}</Box>
                  <Typography
                    variant="subtitle1"
                    align="left"
                    component="p"
                    color="white"
                    letterSpacing="4px"
                  >
                    {" "}
                    <Box sx={{ pt: 2 }}>{allTexts.headings.right}</Box>
                  </Typography>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={6} elevation={6}>
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  mr: 12,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "left",
                }}
              >
                {/* <Typography component="h1" variant="h5">
              CONTACT
            </Typography> */}
              </Box>
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
    </>
  );
};

export default Footer;
