import React from "react";
import "./customerStyles.scss";
import { allTexts } from "../../common/all-text";
import { ReviewCard } from "../../components";
import { Card } from "../../components";
import Carousel from "react-grid-carousel";

// import { customer } from "../../common/images";

export const HappyCustomer = () => {
  const MyDot = ({ isActive }) => (
    <span
      style={{
        display: "inline-block",
        height: isActive ? "9px" : "6px",
        width: isActive ? "9px" : "6px",
        background: isActive ? "#f57f52" : "#C4C4C4",
        borderRadius: "100%",
        marginTop: "8rem",
        marginBottom: "3rem",
      }}
    ></span>
  );
  return (
    <div className="slider-container">
      <h1 className="slider-heading">
        {allTexts.headings.happyCustomer}
      </h1>

      <div className="hc-slider">
        <Carousel
          cols={3}
          rows={1}
          gap={0}
          loop
          showDots
          dot={MyDot}
          responsiveLayout={[
            {
              breakpoint: 400,
              cols: 1,
            },
            {
              breakpoint: 700,
              cols: 4,
            },
            {
              breakpoint: 990,
              cols: 2,
            },
          ]}
        >
          <Carousel.Item>
            <ReviewCard
              icon={allTexts.placeHolders.profileOne}
              Name={allTexts.placeHolders.customerOne}
              paragraph={allTexts.paragraphs.reviewOne}
            />
          </Carousel.Item>
          <Carousel.Item>
            <ReviewCard
              icon={allTexts.placeHolders.profileTwo}
              Name={allTexts.placeHolders.customerTwo}
              paragraph={allTexts.paragraphs.reviewTwo}
            />
          </Carousel.Item>
          <Carousel.Item>
            <ReviewCard
              icon={allTexts.placeHolders.profileThree}
              Name={allTexts.placeHolders.customerThree}
              paragraph={allTexts.paragraphs.reviewThree}
            />
          </Carousel.Item>
          <Carousel.Item>
            <ReviewCard
              icon={allTexts.placeHolders.profileFour}
              Name={allTexts.placeHolders.customerFour}
              paragraph={allTexts.paragraphs.reviewFour}
            />
          </Carousel.Item>
          {/* <Carousel.Item>
            <ReviewCard
              icon={customer}
              Name={allTexts.placeHolders.customer}
              paragraph={allTexts.paragraphs.review}
            />
          </Carousel.Item> */}
        </Carousel>
      </div>
    </div>
  );
};
