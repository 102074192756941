import { motion } from "framer-motion";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const HowPaid = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const validationSchema = Yup.object().shape({
    howPaid: Yup.array().min(1, 'Please select at least one payment method.'),
  });

  const handleNext = (values) => {
    setFormData({ ...formData, howPaidArray: values.howPaid });

    // Store the selected options in sessionStorage
    sessionStorage.setItem('selectedHowPaid', JSON.stringify(values.howPaid));

    
    switch (true) {
      case values.howPaid.includes("Hourly"):
        setPage(7);
        setProgressBar(7000);
        break;
      case values.howPaid.includes("Overtime"):
        setPage(9);
        setProgressBar(9000);
        break;
        case values.howPaid.includes("Salary"):
        setPage(11);
        setProgressBar(11000);
        break;
        case values.howPaid.includes("Commission"):
        setPage(12);
        setProgressBar(12000);
        break;
        case values.howPaid.includes("Bonus"):
        setPage(14);
        setProgressBar(14000);
        break;
        case values.howPaid.includes("Other"):
        setPage(16);
        setProgressBar(16);
        break;
      default:
        break;
    }
  };

  const selectedHowPaidFromSession = JSON.parse(sessionStorage.getItem('selectedHowPaid')) || [];

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="form-container">
        <h2 className="form-content-title">How are you paid? *</h2>
        <Formik
          initialValues={{
            howPaid: selectedHowPaidFromSession,
            otherSelected: selectedHowPaidFromSession.includes("Other"),
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, touched, errors, setFieldValue }) => (
            <Form className="formik-form">
              <label className="checkbox-label">
                <Field className="checkbox" type="checkbox" name="howPaid" value="Hourly" disabled={values.otherSelected}/>
                Hourly
              </label>
              <label className="checkbox-label">
                <Field className="checkbox" type="checkbox" name="howPaid" value="Overtime" disabled={values.otherSelected}/>
                Overtime
              </label>
              <label className="checkbox-label">
                <Field className="checkbox" type="checkbox" name="howPaid" value="Salary" disabled={values.otherSelected}/>
                Salary
              </label>
              <label className="checkbox-label">
                <Field className="checkbox" type="checkbox" name="howPaid" value="Commission" disabled={values.otherSelected}/>
                Commission
              </label>
              <label className="checkbox-label">
                <Field className="checkbox" type="checkbox" name="howPaid" value="Bonus" disabled={values.otherSelected}/>
                Bonus
              </label>
            <label className="checkbox-label">
              <Field
                className="checkbox"
                type="checkbox"
                name="howPaid"
                value="Other"
                onChange={(e) => {
                  setFieldValue("otherSelected", e.target.checked);
                  if (e.target.checked) {
                    setFieldValue("howPaid", ["Other"]);
                  } else {
                    setFieldValue("howPaid", []);
                  }
                }}
              />
              Other
            </label>
              <ErrorMessage name="howPaid" component="div" className="error-message" />
              
              <div className="form-buttons">
                <button
                  className="form-button-differ"
                  onClick={() => {
                    setPage(page - 6);
                    setProgressBar(progressBar - 6000); 
                  }}
                >
                  Previous
                </button>
                <button className="form-button" type="submit" disabled={Object.keys(errors).length > 0}>
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};

export default HowPaid;