import React from 'react'
import './loanOptionsStyles.scss'

const ConventionalLoan = () => {
  return (
    <div className="loanOptionsTypes-container" id="conventional-loan">
        <h3>CONVENTIONAL LOAN</h3>
        <h1 className='title'>Fixed Rate Mortgage</h1>
        <h5>This is our most popular program for refinancing or new home purchases. Offered in 10, 15, 20, 25 and 30-year increments, you rate will be fixed for the entire term of the loan.</h5>
        <div className="advantages-list">
          <h3 className='advantages-title'>ADVANTAGES:</h3>
          <div className="item">
            <h1>01.</h1>
            <h4>Fixed rate over the life of the loan.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>02.</h1>
            <h4>Great for all buyers.</h4>
          </div>
          <div className="divider"></div>
          <div className="item">
            <h1>03.</h1>
            <h4>Allows for a shorter term to pay off your home faster.</h4>
          </div>
          <div className="divider"></div>
        </div>
        <button className='find-rate-button'>APPLY NOW</button>
    </div>
  )
}

export default ConventionalLoan