import axios from 'axios';

const api = axios.create({
    baseURL: 'http://18.220.59.147/',
});

export const getPurchaseCalculation = async (search) => {

    console.log("search", search)
    let result = await api.get
    (`calculator/?loan_amount=${search.loanAmount}&interest_rate=${search.interestRate}&duration_years=${search.durationYear}`);
    return result.data;
};