import { motion } from "framer-motion";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

const PurchasingPlan = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const validationSchema = Yup.object().shape({
    selectedOption: Yup.string().required('Please Select Purchasing Plan'),
  });

  const handleNext = (values) => {
    setFormData({ ...formData, PurchasingPlan: values.selectedOption });
    setPage(page + 1);
    setProgressBar(1000);
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">Puchasing <br /> Plan</div>
      <div className="form-container">
        <h2 className="form-content-title">When are you planning to make your home purchase? *</h2>
        <Formik
          initialValues={{
            selectedOption: '',
          }}
          validationSchema={validationSchema}
          onSubmit={handleNext}
        >
          {({ values, touched }) => (
            <Form>
              <div className="radio-options">
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="Immediately: Signed a Purchase Agreement" />
                  Immediately: Signed a Purchase Agreement
                </label>
                <label className="radio-option"> 
                  <Field type="radio" name="selectedOption" value="ASAP: Found a House/Offer Pending" />
                  ASAP: Found a House/Offer Pending
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="Within 30 Days" />
                  Within 30 Days
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="2 - 3 Months" />
                  2 - 3 Months
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="3 - 6 Months" />
                  3 - 6 Months
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="6+ Months" />
                  6+ Months
                </label>
                <label className="radio-option">
                  <Field type="radio" name="selectedOption" value="No Time Frame/Still Researching Options" />
                  No Time Frame/Still Researching Options
                </label>
              {touched.selectedOption && !values.selectedOption && (
                <div className="error-message">Please select an option</div>
              )}
              </div>
              <div className="form-buttons">
                <button
                  className="form-button-differ"
                  onClick={() => {
                    setPage(page - 1);
                    setProgressBar(-1000);
                  }}
                >
                  Previous
                </button>
                <button className="form-button" type="submit" disabled={!values.selectedOption && touched.selectedOption}>
                  Next
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </motion.div>
  );
};

export default PurchasingPlan;
