import Calculator from "./pages/calculator";
import Home from "./pages/home";
import PurchasingProcess from "./pages/purchasingProcess";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PurchasingProcessResult from "./pages/purchasingProcessResult";
import PreQualify from "./pages/preQualify";
import RefinanceProcess from "./pages/refinanceProcess";
import ApplyNow from "./pages/applyNow";
import RefinanceProcessResult from "./pages/RefinanceProcessResult";
import HELOC from "./pages/heLoc";
import LoanOptions from "./pages/loanOptions";
import AdminLogin from "./pages/admin/adminLogin";
import AdminSignUp from "./pages/admin/adminSignUp";
import AdminDashboard from "./pages/admin/adminDashboard";
import AdminPanel from "./pages/admin/adminPanel";
import ScoreApp from "./pages/scoreApp";
import PreQualifyResult from "./pages/prequalifyResult";


function App() {
  return (
        <Router>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/purchasingProcess" exact element={<PurchasingProcess />} />
            <Route path="/calculator" exact element={<Calculator />} />
            <Route path="/purchasingProcessResult" exact element={<PurchasingProcessResult />} />
            {/* <Route path="/preQualify" exact element={<PreQualify />} /> */}
            <Route path="/refinanceProcess" exact element={<RefinanceProcess />} />
            <Route path="/applyNow" exact element={<ApplyNow />} />
            <Route path="/refinanceProcessResult" exact element={<RefinanceProcessResult />} />
            <Route path="/heloc" exact element={<HELOC />} />
            <Route path="/loanOptions" exact element={<LoanOptions />} />
            <Route path="/adminLogin" exact element={<AdminLogin />} />
            <Route path="/adminSignUp" exact element={<AdminSignUp />} />
            <Route path="/adminDashboard" exact element={<AdminDashboard />} />
            <Route path="/adminPanel" exact element={<AdminPanel />} />
            <Route path="/preQualify" exact element={<ScoreApp />} />
            <Route path="/prequalifyResults" exact element={<PreQualifyResult />} />
          </Routes>
        </Router>
  );
}

export default App;
