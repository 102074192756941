import React from 'react'
import footerMen from '../../images/footerMen.png'
import footerHomeLoan from '../../images/footerHomeLoan.png'
import footerFacebook from '../../images/footerFacebook.png'
import footerTwitter from '../../images/footerTwitter.png'
import footerLinkedin from '../../images/footerLinkedin.png'
import footerInstagram from '../../images/footerInstagram.png'
import footerP from '../../images/footerP.png'
import footerLogo from '../../images/footerLogo.png'
import footerLender from '../../images/footerLender.png'
import { Link } from 'react-router-dom'
import './NewFooterStyles.scss'

const NewFooter = () => {
  return (
    <div className='footer-container'>
        <div className="footer-Cards">
            <div className="footer-card">
                <h2>Loan Officer Details</h2>
                <img src={footerMen} alt="Home Loan"  className='menImage'/>
                <h2>About Me</h2>
                <h6>
                I'm a boutique mortgage brokerage large enough to get the job done and small enough to care.
                </h6>
                <h6>
                    Andrew Winburn <br />
                    Loan Officer <br />
                    andrew@inflation-slayer.com <br />
                    (843) 408-2487 <br />
                    NMLS# 2226753
                </h6>
            </div>
            <div className="footer-card">
                <h2>Company Details</h2>
                <img src={footerHomeLoan} alt="Home Loan" className='homeLoanImage'/>
                <h2>About Us</h2>
                <h6>
                Home Loans Inc is a boutique mortgage brokerage large enough to get the job done and small enough to care.
                </h6>
                <h6>
                    Home Loans Inc. <br />
                    2557 Ashley Phosphate Rd <br />
                    North Charleston, SC 29418 <br />
                    (843) 408-2487 <br />
                    NMLS# 1728740
                </h6>
            </div>
            <div className="footer-card">
                <h2>Tools</h2>
                <Link className='link' to='/'><h6>Contact Us</h6></Link>
                <Link className='link' to='/'><h6>Mortgage Calculator</h6></Link>
                <Link className='link' to='/'><h6>Qualify Me</h6></Link>
                <Link className='link' to='/'><h6>Apply Now</h6></Link>
                <Link className='link' to='/'><h6>Join Our Facebook Group</h6></Link>
                <h2>Resources</h2>
                <Link className='link' to='/'><h6>Mortgage Understanding</h6></Link>
                <Link className='link' to='/'><h6>Loan Process</h6></Link>
                <Link className='link' to='/'><h6>Loan Program</h6></Link>
                <Link className='link' to='/'><h6>FAQs</h6></Link>
            </div>
        </div>
        <div className="footer-Cards">
            <div className="footer-card">
                <h3>Follow Me:</h3>
                <div className="social-icons">
                <img  className='social-icon' src={footerFacebook} alt="Facebook"/>
                <img  className='social-icon' src={footerTwitter} alt="Twitter"/>
                <img  className='social-icon' src={footerLinkedin} alt="Linkedin"/>
                <img  className='social-icon' src={footerInstagram} alt="Instagram"/>
                <img  className='social-icon' src={footerP} alt="P"/>
                </div>
            </div>
            <div className="footer-lender">
                <img src={footerLender} alt="Home Loan" className='lenderImage'/>
                <div className="lender-heading">
                    <h6>
                    “For information purposes only. This is not a commitment to lend or extend credit. Information and/or dates are subject to change without notice. All loans are subject to credit approval.”
                        </h6>
                        <h6>
                            Home Loans Inc | NMLS# <br />
                            Home Loans Inc | NMLS# <br />
                            www.nmlsconsumeraccess.org <br />
                            | Equal Housing Lender
                        </h6>
                </div>
            </div>
            <div className="footer-card">
                <h2>Disclaimer</h2>
                <Link className='link' to='/'><h6>ADA Accessibility Statement</h6></Link>
                <Link className='link' to='/'><h6>Privacy Policy</h6></Link>
                <Link className='link' to='/'><h6>Terms & Conditions</h6></Link>
                <Link className='link' to='/'><h6>Acceptable Use Policy</h6></Link>
            </div>
        </div>
        <div className="footer-divider"></div>
        <div className="footer-bottom">
            <h6>© 2023 Lead Partners, LLC - All Rights Reserved</h6>
            <img src={footerLogo} alt="LOGO" className='footerLogo'/>
        </div>
    </div>
  )
}

export default NewFooter