import { motion } from "framer-motion";
import { useEffect, useState } from "react";

  const ConventionalPurchasePrice = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const conventionalSession = sessionStorage.getItem('conventionalAmount') || 0;
    const initialValue = parseInt(conventionalSession, 10); 
    const [conventionalPrice, setConventionalPrice] = useState(initialValue);
    const [disable, setDisable] = useState(false);
  
    const handleIncrement = () => {
      setConventionalPrice(parseFloat(conventionalPrice) + 1000);
    }
    const handlePurchaseChange = (event) => {
      setConventionalPrice(event.target.value);
      setDisable(true);
    };
    useEffect(() => {
      sessionStorage.setItem('conventionalAmount', conventionalPrice);
      setFormData({ ...formData, conventionalPurchasePrice: conventionalPrice })
    }, [conventionalPrice]);

    const handlePrevius = () => {
      if (formData.buyingArea == "Yes") {
        setPage(29);
        setProgressBar(29000);
      }  
      else if (formData.buyingArea == "No") {
        setPage(page - 2);
        setProgressBar(progressBar - 2000);
      }   
      else{
        setPage(24);
        setProgressBar(24000);
      }
    };
    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        {/* <h1 className="step-title">Conventional Purchase Price</h1> */}
        <div className="form-container">
        <h2 className="form-content-title">What is the estimated purchase price?</h2>
        <div className="estimated-field">
          <label>PURCHASE PRICE</label>
          <div className="estimated-price">
          <input placeholder={`$ ${conventionalPrice}`} onChange={handlePurchaseChange} type="number"/>
        <div className="price-counter">
          <button disabled={disable} className="minus-sign" onClick={() => setConventionalPrice(conventionalPrice - 1000)}>-</button>
          <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={handlePrevius}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={() => {
            setPage(page + 1);
            setProgressBar(1000);
          }}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default ConventionalPurchasePrice;