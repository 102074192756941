import { motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';

const ScheduleMeeeting = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const data = formData;
  const navigate = useNavigate();

  const [selectedScheduleMeeting, setScheduleMeeting] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  const validationSchema = Yup.object().shape({
    dateTime: Yup.date().required('Please select a date and time'),
  });

  const handleSubmitDate = (e) => {
    e.preventDefault();

    validationSchema
      .validate({ dateTime: selectedScheduleMeeting })
      .then((valid) => {
        setErrorMessage('');
        setFormData({ ...formData, scheduleMeeting: selectedScheduleMeeting });
      })
      .catch((error) => {
        // Validation failed, display error message
        setErrorMessage(error.message);
      });
  };
  
      const onSubmit = () => {
        navigate('/prequalifyResults', { state: data });
      };
  
      const handlePrevious = () => {
        if (formData.derogatoryFailure == "true"){
          setPage(4);
          setProgressBar(4000);
        }
        else if (formData.failure == "true"){
          setPage(5);
          setProgressBar(5000);
        }
        else if (formData.vetFailure == "true"){
          setPage(28);
          setProgressBar(28000);
        }
        else if (formData.RuralFailure == "true"){
          setPage(33);
          setProgressBar(33000);
        }
        else if (formData.conventionalFail == "true"){
          setPage(37);
          setProgressBar(37000);
        }
        else if (formData.fhaFail == "true"){
          setPage(40);
          setProgressBar(40000);
        }
        else{
          setPage(page - 1);
          setProgressBar(-1000);
        }
      }

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      {/* <div className="step-title">Schedule a Meeting</div> */}
      <div className="form-container">
        <h2 className="form-content-title">For the appointment meeting with loan officer, please select a date and time:</h2>
        <div>
          <form onSubmit={handleSubmitDate} className="formik-form">
            <DatePicker
              selected={selectedScheduleMeeting}
              onChange={(date) => setScheduleMeeting(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholderText="Select date and time"
              className="custom-input"
            />
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <button type="submit" className="date-time-button" onClick={() => setFormData({ ...formData, scheduleMeetingValue: 5 })}>Schedule a Meeting</button>
          </form>
        </div>
        <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={handlePrevious}
          >
            Previous
          </button>
          <button className="form-button" onClick={onSubmit}>
            Submit
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default ScheduleMeeeting;
