import React from 'react'
import PurchasingSteps from '../../components/purchasingSteps/puchasingSteps'
import MainLayout from '../../components/layout/layout'

const PurchasingProcess = () => {
  return (
    <MainLayout>
      <PurchasingSteps />
    </MainLayout>
  )
}

export default PurchasingProcess