import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Link } from 'react-router-dom';

export default function PurchasingProcessTable({data, isLoading}) {
  console.log("shan", data)
  
  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleRowSelect = (event, row) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((r) => r !== row));
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 1050 }} size="large" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                sx={{
                  color: "#f57f52",
                  '&.Mui-checked': {
                    color: "#f57f52",
                    '&.MuiSvgIcon-root': {
                      color: "#f57f52",
                    },
                  },
                }}
                checked={selectedRows.length === data?.mortech?.results?.length}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSelectedRows(data?.mortech?.results || []);
                  } else {
                    setSelectedRows([]);
                  }
                }}
                indeterminate={
                  selectedRows.length > 0 && selectedRows.length < data?.mortech?.results?.length
                }
              />
            </TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">Interest Rate</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">APR</TableCell>
            {/* <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">Vender Name</TableCell> */}
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">Vender Product Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">Payment (P + I)</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">Total Profit</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">Total Profit Percent</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">ACTIONS</TableCell>
            {/* <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">Copy Link</TableCell> */}
            {/* <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">Rate Tracking</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">Apply</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: 14, color: "#f57f52" }} align="center">Qualify</TableCell> */}
          </TableRow>
        </TableHead>
        {isLoading ? "Loading..." : 
        <TableBody>
          {data?.mortech?.results?.map((row) => (
            <TableRow
              key={row.index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell padding="checkbox">
                <Checkbox
                  sx={{
                    color: "#0c1137",
                    '&.Mui-checked': {
                      color: "#0c1137",
                    },
                  }}
                  checked={selectedRows.indexOf(row) !== -1}
                  onChange={(event) => handleRowSelect(event, row)}
                />
              </TableCell>
              <TableCell align="center"> {row.quote.quote_detail.$.rate} % </TableCell>
              <TableCell align="center">{row.quote.quote_detail.$.apr} %</TableCell>
              <TableCell align="center">{row.quote.$.vendor_name}</TableCell>
              {/* <TableCell align="center">{row.quote.$.vendor_product_name}</TableCell> */}
              <TableCell align="center">{row.quote.quote_detail.$.piti}</TableCell>
              <TableCell align="center">$ {row.quote.quote_detail.costs_and_profit.$.total_cost_profit_dollar}</TableCell>
              <TableCell align="center">{row.quote.quote_detail.costs_and_profit.$.total_cost_profit_percent} %</TableCell>
              <TableCell align="center"><Link to="/preQualify"><button className='data-cell-button'>PRE QUALIFY ME</button></Link></TableCell>
              {/* <TableCell align="center"><Link className='data-cell-link' to={row.copyLink}><FaLink /></Link></TableCell> */}
              {/* <TableCell align="center"><button className='data-cell-button'>RATE TRACKING</button></TableCell>
              <TableCell align="center"><button className='data-cell-button'>PRE-APPROVE ME</button></TableCell>
              <TableCell align="center"><button className='data-cell-button'>QUALIFY ME</button></TableCell> */}
            </TableRow>
          ))}
        </TableBody>
}
      </Table>
    </TableContainer>
  );
}
