import React from 'react'
import './incomeResultsSteps.scss'

const IncomeResultTwo = ({ income }) => {
  return (
    <div className='income-result-component'>
        <div className="income-result-headings">
            <h1 className="title">Income Section: Building Stability – 70-85% Score Range</h1>
            <p className='paragraph'>Your 70-85% score in the Income Section shows that you're building a stable foundation. You're moving in the right direction, and with some fine-tuning, you can align your income situation with your homeownership dreams.</p>
        </div>
        <div className="percentage">
          <h5>Income Score</h5>
          <h2>{income}%</h2>
          <div className="medium-box">
            Medium
          </div>
        </div>
    </div>
  )
}

export default IncomeResultTwo