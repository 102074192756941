import React, { useState } from 'react'
import FilterButtons from './filterbar'
import RefinanceProcessTable from './refinanceProcessTable'
import './refinanceProcessResults.scss'
import { useLocation } from "react-router-dom";

const RefinanceProcessResults = () => {
  const location = useLocation();
  const [data, setData] = useState(location.state?.data);
  const [formValues, setFormValues] = useState(location.state?.formData);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div className="refinance-process-results">
        <FilterButtons data={data} setData={setData} isLoading={isLoading} setIsLoading={setIsLoading} formValues={formValues} setFormValues={setFormValues}/>
        <RefinanceProcessTable data={data} isLoading={isLoading} />
    </div>
  )
}

export default RefinanceProcessResults