import { motion } from "framer-motion";
import { useState, useEffect } from "react";

  const DownPayment = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const [downPayment, setDownPayment] = useState(formData.purchasePrice * 10/100);
    const handleDownPaymentChange = (event) => {
      setDownPayment(event.target.value);
    };
    useEffect(() => {
      setFormData({ ...formData, downPrice: downPayment })
    }, [downPayment]);
    
    const Percentage = (downPayment * 100)/formData.purchasePrice;
    
    // const downPayment = (downPayment * 10/100);

    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        <h1 className="step-title">your new home ?</h1>
        <div className="form-container">
        <h2 className="form-content-title">How much do you want to put down?</h2>
        <div className="estimated-field">
          <label>Down Payment</label>
          <div className="estimated-price">
          <input placeholder={`$ ${downPayment}`} onChange={handleDownPaymentChange} />
          <input placeholder={`$ ${Percentage}%`} className="percentage-input"/>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 1);
            setProgressBar(-1000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={() => {
            setPage(page + 1);
            setProgressBar(1000);
          }}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default DownPayment;