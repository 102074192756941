import { motion } from "framer-motion";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import Modal from "../../reusableComponents/Model/modal";

const SubmitForm = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    dateTime: Yup.date().required('Please select a date and time'),
  });

  const handleSubmitDate = (e) => {
    e.preventDefault();

    validationSchema
      .validate({ dateTime: selectedDateTime })
      .then((valid) => {
        setErrorMessage('');
        setFormData({ ...formData, dateTime: selectedDateTime });
      })
      .catch((error) => {
        // Validation failed, display error message
        setErrorMessage(error.message);
      });
  };

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const onSubmit = (e) => {
    setShowModal(true);
  };

  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => {
        setShowModal(false);
        navigate('/');
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showModal, navigate]);

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">Let's see <br /> this <br /> quote.</div>
      <div className="form-container">
        <h2 className="form-content-title">For the appointment, please select a date and time:</h2>
        <div>
          <form onSubmit={handleSubmitDate} className="formik-form">
            <DatePicker
              selected={selectedDateTime}
              onChange={(date) => setSelectedDateTime(date)}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
              placeholderText="Select date and time"
              className="custom-input"
            />
            {errorMessage && <div className="error-message">{errorMessage}</div>}
            <button type="submit" className="date-time-button">Set Date and Time</button>
          </form>
        </div>
        <div className="form-buttons">
          <button
            className="form-button-differ"
            onClick={() => {
              setPage(page - 1);
              setProgressBar(-1000);
            }}
          >
            Previous
          </button>
          <button className="form-button" onClick={onSubmit}>
            Submit
          </button>
        </div>
        <div className="col-md-3">
          {showModal && (
            <Modal
              title="Thanks for your interest"
              content={"Our concerned person will contact you via the provided mean of contact."}
              onClose={handleCloseModal}
            />
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default SubmitForm;
