import { motion } from "framer-motion";
import { useEffect, useState } from "react";

  const EstimatedPrice = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const [estimatedPrice, setsetEstimatedPrice] = useState(40000);
    const handlePurchaseChange = (event) => {
      setsetEstimatedPrice(event.target.value);
    };
    useEffect(() => {
      setFormData({ ...formData, purchasePrice: estimatedPrice })
    }, [estimatedPrice]);

    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        <h1 className="step-title">your new home ?</h1>
        <div className="form-container">
        <h2 className="form-content-title">What’s the estimated purchase price?</h2>
        <div className="estimated-field">
          <label>PURCHASE PRICE</label>
          <div className="estimated-price">
          <input placeholder={`$ ${estimatedPrice}`} onChange={handlePurchaseChange}/>
        <div className="price-counter">
          <span className="minus-sign" onClick={() => setsetEstimatedPrice(estimatedPrice - 1000)}>-</span>
          <span className="plus-sign" onClick={() => setsetEstimatedPrice(estimatedPrice + 1000)}>+</span>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 1);
            setProgressBar(-1000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={() => {
            setPage(page + 1);
            setProgressBar(1000);
          }}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default EstimatedPrice;