import React from 'react'
import UsersTable from './usersTable'
import './adminUsers.scss'

const AdminUsers = () => {
  return (
    <div className='admin-users-container'> 
      <h1>Realtors Detail</h1>
      <UsersTable />
    </div>
  )
}

export default AdminUsers