import React from 'react'
import './totalResultsSteps.scss'
import TotalResults from '../../scoreCharts/allResults'

const TotalResultThree = ({ credit, income, debtResult, assets, creditColor, incomeColor, debtColor, assetsColor }) => {
  return (
    <div className='total-result-component'>
        <div className="total-result-headings">
            <h1 className="title">A Journey Worth Taking: 0-69% Score Range</h1>
            <p className='paragraph'>Your PreQual Quiz results have placed you in the 0-69% score range. While this may seem like a setback, it's important to recognize that it's merely a starting point on the path to homeownership. This range doesn't mean that your dream of owning a home is out of reach; it simply indicates areas where additional focus and guidance might be needed. Let's break down what this means for you:</p>
        </div>
        <div className="total-results-chart">
            <TotalResults credit={credit} income={income} debtResult={debtResult} assets={assets} creditColor={creditColor} incomeColor={incomeColor} debtColor={debtColor} assetsColor={assetsColor} />
            <div className="hider"></div>
        </div>
    </div>
  )
}

export default TotalResultThree