import { motion } from "framer-motion";
import ToggleButtons from "../../reusableComponents/toggleButton/toggleButton";
import { useEffect, useState } from "react";

  const CashOut = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const [toggleState, setToggleState] = useState(false);
  
    const handleToggleChange = () => {
      setToggleState(!toggleState);
    };
    useEffect(() => {
      setFormData({ ...formData, cashOut: toggleState })
    }, [toggleState]);
    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        <h1 className="step-title">Cash out</h1>
        <div className="form-container">
        <h2 className="form-content-title">Do you want to borrow additional cash when you refinance?</h2>
            <ToggleButtons label1="NO" label2="YES" onChange={handleToggleChange}  />
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 1);
            setProgressBar(-1000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={() => {
            setPage(page + 1);
            setProgressBar(1000);
          }}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default CashOut;