import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

  const FHAPurchasePrice = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
    const fhaSession = sessionStorage.getItem('fhaAmount') || 0;
    const initialValue = parseInt(fhaSession, 10); 
    const [fhaPrice, setFhaPrice] = useState(initialValue);
    const [disable, setDisable] = useState(false);
  
    const handleIncrement = () => {
      setFhaPrice(parseFloat(fhaPrice) + 1000);
    }
    const handlePurchaseChange = (event) => {
      setFhaPrice(event.target.value);
      setDisable(true);
    };
    useEffect(() => {
      sessionStorage.setItem('fhaAmount', fhaPrice);
      setFormData({ ...formData, fhaPurchasePrice: fhaPrice })
    }, [fhaPrice]);
    
    let PITI;
    let totalIncome;
    let monthlSalary=formData.bonusIncomeAmount;

    const hourlyIncome = (formData.weeklyHours * formData.hourlyRate) * 4.33;
    const bonusIcome = formData.bonusIncomeAmount/12;
    const overTimeIncome = formData.overtimeIncomeAmount/12;
    const commissionIncome = formData.commissionIncomeAmount/12;

    totalIncome = hourlyIncome +  + bonusIcome + overTimeIncome + commissionIncome;
    
      PITI=(fhaPrice*0.007);
      const DEBT = parseInt(formData.debtAmount, 10);
      const First = PITI + DEBT;
      const Second = First / totalIncome;
      const DTI = Second * 100;
  
  const onSubmit = () => {
    console.log("dti", DTI)
    if(DTI <= 50){
      setPage(page + 1);
      setProgressBar(1000); 
    }
    else{
      setPage(page + 2);
      setProgressBar(2000); 
    }
  };
console.log("piti", PITI)
const handlePrevius = () => {
  if (formData.buyingArea == "Yes") {
    setPage(29);
    setProgressBar(29000);
  }  
  else if (formData.buyingArea == "Poor (Below 580)") {
    setPage(32);
    setProgressBar(32000);
  }   
  else{
    setPage(24);
    setProgressBar(24000);
  }
};
    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        {/* <h1 className="step-title">FHA Purchase Price</h1> */}
        <div className="form-container">
        <h2 className="form-content-title">What is the estimated purchase price?</h2>
        <div className="estimated-field">
          <label>PURCHASE PRICE</label>
          <div className="estimated-price">
          <input placeholder={`$ ${fhaPrice}`} onChange={handlePurchaseChange} type="number"/>
        <div className="price-counter">
          <button disabled={disable} className="minus-sign" onClick={() => setFhaPrice(fhaPrice - 1000)}>-</button>
          <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={handlePrevius}
        >
          Previous
        </button>
        <button className="form-button" type="submit" onClick={onSubmit}>
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default FHAPurchasePrice;