import { motion } from "framer-motion";
import { useEffect, useState } from "react";

  const WeeklyHours = ({page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
      
  const weekHoursSession = sessionStorage.getItem('selectedWeekHours') || 0;
  const initialValue = parseInt(weekHoursSession, 10); 
    const [weekHours, setWeekHours] = useState(initialValue);
    const [disable, setDisable] = useState(false);
  
    const handleIncrement = () => {
      setWeekHours(parseFloat(weekHours) + 1);
    }
    const handlePurchaseChange = (event) => {
      setWeekHours(event.target.value);
      setDisable(true)
    };
    useEffect(() => {
      sessionStorage.setItem('selectedWeekHours', weekHours);
      setFormData({ ...formData, weeklyHours: weekHours })
    }, [weekHours]);

    const handleNext = () => {
      switch (true) {
        case formData.howPaidArray.includes("Overtime"):
          setPage(9);
          setProgressBar(9000);
          break;
          case formData.howPaidArray.includes("Salary"):
          setPage(11);
          setProgressBar(11000);
          break;
          case formData.howPaidArray.includes("Commission"):
          setPage(12);
          setProgressBar(12000);
          break;
          case formData.howPaidArray.includes("Bonus"):
          setPage(14);
          setProgressBar(14000);
          break;
        default:
          setPage(18);
          setProgressBar(18000);
          break;
      }
    };
    return (
      <motion.div
        initial={{ progressBar: progressBar }}
        animate={{ progressBar: 0 }}
        transition={{ duration: 1 }}
        className="form"
      >
        {/* <h1 className="step-title">Weekly Hours</h1> */}
        <div className="form-container">
        <h2 className="form-content-title">How many hours a week do you work? *</h2>
        <div className="estimated-field">
          <label>Enter Weekly Hours</label>
          <div className="estimated-price">
          <input placeholder={weekHours} onChange={handlePurchaseChange} type="number"/>
        <div className="price-counter">
          <button disabled={disable} className="minus-sign" onClick={() => setWeekHours(weekHours - 1)}>-</button>
          <button disabled={disable} className="plus-sign" onClick={handleIncrement}>+</button>
        </div>
          </div>
        </div>
        <div className="form-buttons">
        <button className="form-button-differ"
          onClick={() => {
            setPage(page - 1);
            setProgressBar(-1000);
          }}
        >
          Previous
        </button>
        <button className="form-button"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
        </div>
      </motion.div>
    );
  };

  export default WeeklyHours;