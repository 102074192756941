import React from 'react'
import './assetsResultsSteps.scss'

const AssetsResultThree = ({ assets }) => {
  return (
    <div className='assets-result-component'>
        <div className="assets-result-headings">
            <h1 className="title">Assets Section: The Path to Prosperity – 0-69% Score Range</h1>
            <p className='paragraph'>Scoring in the 0-69% range in the Assets Section marks the beginning of a path to prosperity. It's an opportunity to explore and build upon your assets with expert guidance.</p>
        </div>
        <div className="percentage">
          <h5>Assets Score</h5>
          <h2>{assets}%</h2>
          <div className="low-box">
            Low
          </div>
        </div>
    </div>
  )
}

export default AssetsResultThree