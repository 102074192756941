import { motion } from "framer-motion";
import ToggleButtons from "../../reusableComponents/toggleButton/toggleButton";
import FormInput from "../../reusableComponents/formInput/formInput";
import { useState } from "react";
import { useEffect } from "react";

const SignUp = ({ page, setPage, progressBar, setProgressBar, formData, setFormData }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorMessageTwo, setErrorMessageTwo] = useState('');
  const [toggleState, setToggleState] = useState(false);

  const handleToggleChange = () => {
    setToggleState(!toggleState); 
  };

  useEffect(() => {
    setFormData({ ...formData, isMilitry: toggleState })
  }, [toggleState]);

  const handleSubmit = () => {
    if (formData.firstName.trim() === '') {
      setErrorMessage('First Name is Required.');
    } else {
      setErrorMessage('');
    }

    if (formData.lastName.trim() === '') {
      setErrorMessageTwo('Last Name is Required.');
    } else {
      setErrorMessageTwo('');
    }

    if (formData.firstName.trim() !== '' && formData.lastName.trim() !== '') {
      setPage(page + 1);
      setProgressBar(1000);
    }
  };

  return (
    <motion.div
      initial={{ progressBar: progressBar }}
      animate={{ progressBar: 0 }}
      transition={{ duration: 1 }}
      className="form"
    >
      <div className="step-title">free rate quote</div>
      <div className="form-container">
        <h2 className="form-content-title">If you’re interested, why wait? Apply now or get a free refinance rate quote!</h2>
        <div className="form-fields">
          <FormInput
            label="First Name *"
            value={formData.firstName}
            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            required
            error={errorMessage}
          />
          <FormInput
            label="Last Name * "
            value={formData.lastName}
            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            required
            error={errorMessageTwo}
          />
        </div>
        <div className="form-fields">
          <p>HAVE YOU OR YOUR SPOUSE SERVED IN THE MILITARY?</p>
          <ToggleButtons label1="NO" label2="YES" onChange={handleToggleChange} />
        </div>
        <div className="form-buttons">
          <button className="form-button" onClick={handleSubmit}>
            Next
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default SignUp;
