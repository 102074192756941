export const fb = "/images/facebook.svg";
export const linkedIn = "/images/linkedin.svg";
export const insta = "/images/insta.svg";
export const arrow = "/images/arrow.svg";
// export const house = "/images/house.svg";
// export const dollar = "/images/dollar.svg";
export const quote = "/images/qoute.jpg";
export const Inflationlogo = "/images/Inflation Slayer Logo 2.png";
export const InflationlogoDark = "/images/Inflation Slayer Logo.png";
export const customer = "/images/customer.jpg";
export const revenue = "/images/Revenue.jpeg";
export const time = "/images/time.png";
export const review = "/images/Reviews.jpg";
export const clock = "/images/Clock.jpeg";
export const pqmnLogoLogo = "/images/pqmnLogo.jpeg";
export const house = "/images/home.png";
export const dollar = "/images/dollar.png";
export const calculator = "/images/calculator.png";

export const clockOne = "/images/clockOne.jfif";
export const clockTwo = "/images/ClockTwo.jpg";

