import React from 'react'
import './totalResultsSteps.scss'
import TotalResults from '../../scoreCharts/allResults'

const TotalResultTwo = ({ credit, income, debtResult, assets, creditColor, incomeColor, debtColor, assetsColor }) => {
  return (
    <div className='total-result-component'>
        <div className="total-result-headings">
            <h1 className="title">Navigating the Path to Homeownership: 70-85% Score Range</h1>
            <p className='paragraph'>You've completed the PreQual Quiz and landed in the 70-85% score range. That's a significant achievement, and it means that you most likely qualify for a mortgage loan! This encouraging result is a testament to your efforts, but it's only part of the journey towards your dream home. Let's explore what this score range means for you.</p>
        </div>
        <div className="total-results-chart">
            <TotalResults credit={credit} income={income} debtResult={debtResult} assets={assets} creditColor={creditColor} incomeColor={incomeColor} debtColor={debtColor} assetsColor={assetsColor} />
            <div className="hider"></div>
        </div>
    </div>
  )
}

export default TotalResultTwo